import React from 'react'

const OutlineSlashIcon = ({ fill = '#292D32', size = 18 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
        fill={fill}
      />
      <path
        d="M4.9 19.75C4.71 19.75 4.52 19.68 4.37 19.53C4.08 19.24 4.08 18.76 4.37 18.47L18.37 4.47C18.66 4.18 19.14 4.18 19.43 4.47C19.72 4.76 19.72 5.24 19.43 5.53L5.43 19.53C5.28 19.68 5.09 19.75 4.9 19.75Z"
        fill={fill}
      />
    </svg>
  )
}

export default OutlineSlashIcon
