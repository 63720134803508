import React from 'react'
import {BrowserRouter, Link, Route, Switch} from 'react-router-dom'
import 'antd/dist/antd.css'
import './App.css'

import Login from './Components/Login.js'
import Main from './Components/Main.js'
import RouteProtected from './Components/RouteProtected.js'
import Developer from './Components/developer/Developer.js'

import {Provider} from 'react-redux'
import thunk from 'redux-thunk'
import {applyMiddleware, compose, createStore} from 'redux'
import {rootReducer} from './redux/rootReducer'
import {QueryClientProvider,QueryClient} from "react-query";
// import { ReactQueryDevtools } from 'react-query/devtools'

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ({})
    : compose

const enhancer = composeEnhancers (applyMiddleware (thunk))

const store = createStore (rootReducer, enhancer)

const queryClient = new QueryClient ()

function App(){
  return (
    <div>
      <Provider store={store}>
        <QueryClientProvider client={queryClient} contextSharing={true}>
          <BrowserRouter>
            <Switch>
              <Route exact path="/">
                <Login/>
              </Route>
              <RouteProtected path="/main">
                <Main/>
              </RouteProtected>
              <RouteProtected path="/developer">
                <Developer/>
              </RouteProtected>
              <Route path="*">
                404 error
                <br/>
                go to <Link to="/">Login page</Link>
              </Route>
            </Switch>
          </BrowserRouter>
          {/*<ReactQueryDevtools initialIsOpen={false} />*/}
        </QueryClientProvider>
      </Provider>
    </div>
  )
}

export default App
