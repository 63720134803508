import { useQuery } from 'react-query'
import { queryKeys } from '../utils/constants/queryKeys'
import $api from '../../../http/$api'
import { endPoints } from '../utils/constants/endPoints'

export function useBrokerAll(searchString) {
  return useQuery([queryKeys.BROKER_ALL, searchString], async () => {
    const res = await $api.get(endPoints.BROKER_ALL + searchString)
    return res?.data?.data
  })
}

export function useBrokerCompact() {
  return useQuery([queryKeys.BROKER_COMPACT], async () => {
    const res = await $api.get(endPoints.BROKER_COMPACT)
    return res?.data?.data
  })
}
