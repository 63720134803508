import React from 'react'
import { Spin, Table, Typography } from 'antd'
import { HiOutlineCheckCircle } from 'react-icons/hi'
import { RiCloseCircleLine, RiErrorWarningLine } from 'react-icons/ri'
import NumberFormat from 'react-number-format'
import { LoadingOutlined } from '@ant-design/icons'

const { Column } = Table
const antIcon = (
  <LoadingOutlined
    style={{ fontSize: 12, marginLeft: '10px', color: '#5f5e5e' }}
    spin
  />
)

function TableNextPayment(props) {
  return (
    <div>
      <Typography className="ClientAddHeader" style={{ paddingTop: '10px' }}>
        To'lov jadvali
      </Typography>
      <Table
        loading={props.loading}
        showSizeChanger={true}
        size={'middle'}
        dataSource={props.data?.monthlyPaymentPartDtoList || []}
        pagination={false}
      >
        <Column
          title="№"
          key="date"
          render={(text, record, index) => {
            return <span>{index + 1}</span>
          }}
        />
        <Column
          title={() => (
            <span>
              Sana
              {props.isFetching && <Spin indicator={antIcon} />}
            </span>
          )}
          dataIndex="date"
          key="date"
        />
        <Column
          title="Summa"
          dataIndex="amount"
          key="amount"
          render={(amount) => (
            <>
              <NumberFormat
                value={amount}
                displayType={'text'}
                thousandSeparator={' '}
              />{' '}
              {props?.currency}
            </>
          )}
        />
        <Column
          title="To'langan"
          dataIndex="payment"
          key="payment"
          render={(payment) => (
            <>
              <NumberFormat
                value={payment}
                displayType={'text'}
                thousandSeparator={' '}
              />{' '}
              {props?.currency}
            </>
          )}
        />
        <Column
          key="paid"
          render={(record) => (
            <div
              style={{
                width: '27px',
                height: '27px',
                fontSize: '1.6rem',
                display: 'flex',
                alignItems: 'start',
                padding: '3px',
                backgroundColor: record.paid
                  ? '#B5FFF2'
                  : record.payAmount !== 0
                  ? '#FCFA53'
                  : '#FFDFE3',
                color: record.paid
                  ? '#00957A'
                  : record.payAmount !== 0
                  ? '#A4B507'
                  : '#AE001F',
                borderRadius: '5px',
              }}
            >
              {record.paid ? (
                <HiOutlineCheckCircle
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                />
              ) : record.payAmount !== 0 ? (
                <RiErrorWarningLine
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                />
              ) : (
                <RiCloseCircleLine
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                />
              )}
            </div>
          )}
        />
      </Table>
    </div>
  )
}

export default TableNextPayment
