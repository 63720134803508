import React from 'react'

const ArrowBottom = ({ className }) => {
  return (
    <svg
      className={className}
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00492 1H8.0926C10.6807 1 11.7441 2.56765 10.4461 4.48218L9.39838 6.02314L8.35063 7.5641C7.05267 9.47863 4.93371 9.47863 3.63575 7.5641L2.58799 6.02314L1.54024 4.48218C0.265736 2.56765 1.32131 1 3.91723 1H6.00492Z"
        stroke="#1D1D1D"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ArrowBottom
