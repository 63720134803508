import { locales } from '../constants/locales'
import { removeNaN } from './removeNaN.js'

export const customCurrency = (value) => {
  let newValue = removeNaN(value)

  const arr = newValue.split('.')

  if (arr.length === 1) {
    return newValue ? (+newValue).toLocaleString(locales.RU) : ''
  } else if (arr.length === 2) {
    return newValue
      ? (+arr[0]).toLocaleString(locales.RU) + '.' + arr[1]?.slice(0, 2)
      : ''
  } else {
    return newValue ? (+arr[0]).toLocaleString(locales.RU) + '.' + arr[1] : ''
  }
}
