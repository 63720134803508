import { Button, Col, Form, Input, Row, Spin, Switch, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSmsMutation } from '../../../queries/mutations'
import { useSmsSettings } from '../../../queries/queries'
import { stringToBoolAndNumber } from '../../../services/StringToBool'
import EditModal from '../../developer/companies/EditModal'

const { Item, useForm } = Form
const fields = [
  {
    name: 'SENT_SMS_DEBT',
    label: 'Qarzdorlik xabarlari',
  },
  {
    name: 'SENT_SMS_PAYMENT',
    label: "To'lov qilingandan so'ngi xabarlari",
  },
  {
    name: 'SENT_SMS_CONTRACT',
    label: 'Shartnoma yararish xabarlari',
  },
  {
    name: 'SENT_SMS_BEFORE',
    label: "To'lov kunidan 1 kun oldingi xabarlar",
  },
  {
    name: 'SENT_SMS_SUCCESS_CONTRACT',
    label: 'Shartnoma tugallangandagi xabarlar',
  },
  {
    name: 'SENT_SMS_CANCEL_CONTRACT',
    label: 'Shartnoma bekor qilingandagi xabarlarr',
  },
  {
    name: 'AUTO_PAY',
    label: "Avtomatik to'lov",
  },
]

export const OtherCompanySettings = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [form] = useForm()
  const { data, isLoading } = useSmsSettings()
  const mutation = useSmsMutation()

  const onSettingToggle = (key, value) => {
    mutation.mutate({
      value: value === true ? 'true' : value === false ? 'false' : value,
      key,
    })
  }

  useEffect(() => {
    if (data) {
      data.forEach((item) => {
        // const value = stringToBoolAndNumber(item.value)
        form.setFieldsValue({
          [item.key]: stringToBoolAndNumber(item.value),
        })
      })
    }
  }, [data, form])

  return (
    <div
      style={{
        background: '#FFFFFF',
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.05)',
        borderRadius: 6,
        padding: 24,
      }}
    >
      <Row gutter={16}>
        {isLoading ? (
          <Col span={24}>
            <Spin
              style={{ margin: '4rem auto', width: '100%' }}
              spinning
              tip="yuklanmoqda"
            />
          </Col>
        ) : (
          <>
            <Col span={24} style={{ marginBottom: '1rem' }}>
              <Typography.Title level={4} type="secondary">
                Kompaniya sozlanmalari
              </Typography.Title>
              <Button type="primary" onClick={() => setIsOpen(true)}>
                o'zgartirish
              </Button>
              {/* </Space> */}
            </Col>
            <Col span={24}>
              <Typography.Title level={4} type="secondary">
                Xabarlarni sozlash
              </Typography.Title>
            </Col>
            <Col span={24}>
              <Form form={form} layout="vertical">
                <Row gutter={16}>
                  <Col span={12}>
                    <div style={{ display: 'flex' }}>
                      <Item
                        style={{ flexGrow: 1 }}
                        label="Qarzdorlik xabarlarini yuborish intervali"
                        name="SENT_SMS_DELAY"
                      >
                        <Input />
                      </Item>

                      <Button
                        style={{
                          marginTop: 'auto',
                          marginBottom: '24px',
                          marginRight: '100px',
                        }}
                        type="primary"
                        onClick={() => {
                          onSettingToggle(
                            'SENT_SMS_DELAY',
                            form.getFieldValue('SENT_SMS_DELAY')
                          )
                        }}
                      >
                        Ok
                      </Button>
                    </div>
                  </Col>
                  {fields.map((field) => {
                    return (
                      <Col key={field.name} span={12}>
                        <Item
                          valuePropName="checked"
                          label={field.label}
                          name={field.name}
                        >
                          <Switch
                            onChange={(value) => {
                              onSettingToggle(field.name, value)
                            }}
                          />
                        </Item>
                      </Col>
                    )
                  })}
                </Row>
              </Form>
            </Col>
          </>
        )}
      </Row>
      <EditModal open={isOpen} close={() => setIsOpen(false)} isCompany />
    </div>
  )
}
