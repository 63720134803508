import { Card, Spin } from 'antd'
import React, { useState } from 'react'
import { useBlackListSame } from '../../services/queries'
import styles from './blacklistSame.module.scss'

const BlacklistSame = ({ fullName = '', phone = '', visible = false }) => {
  const [scrolling, setScrolling] = useState(true)
  const { data, fetchNextPage, isLoading } = useBlackListSame({
    fullName,
    phone,
  })

  const onScroll = (e) => {
    if (
      e.target.offsetHeight + e.target.scrollTop + 200 >=
        e.target.scrollHeight &&
      e.target.scrollHeight > e.target.offsetHeight
    ) {
      setScrolling(false)
      scrolling &&
        fetchNextPage().then(() => {
          setScrolling(true)
        })
    }
  }

  return (data?.pages && data?.pages?.length > 0) || visible ? (
    <Card className={styles.container} title="Qora ro'yxat" onScroll={onScroll}>
      {data?.pages?.map((page) =>
        page?.data?.map((item) => (
          <Card
            key={item?.id}
            style={{ marginTop: 16 }}
            type="inner"
            title={item?.clientName}
          >
            <p>{item?.phone}</p>
          </Card>
        ))
      )}
      {isLoading && <Spin spinning={true} />}
    </Card>
  ) : null
}

export default BlacklistSame
