import { Button, Pagination, Typography } from 'antd'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { CgMathPlus } from 'react-icons/all'
import { useQueryString } from '../../../hooks/useQueryString'
import { apis } from '../../../http/apis'
import { useBuilderCompany } from '../../../queries/queries'
import EditModal from './EditModal'
import TableCompanyEmployees from './TableCompanyEmployees'

const CompanyEmployees = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { data, isLoading } = useBuilderCompany(apis.EMPLOYEES)
  const { push, query } = useQueryString()
  useEffect(() => {
    push('page', 1)
    //eslint-disable-next-line
  }, [])
  return (
    <div>
      <div className={'dashboardContentsTitleCont'}>
        <Typography>Xodim qo‘shish</Typography>
        <Button
          onClick={() => setIsOpen(true)}
          className={'dashboardContentPlusIcon'}
          shape="circle"
          icon={<CgMathPlus />}
        />
      </div>
      <div className={'dashboardContentsTableCon'}>
        <TableCompanyEmployees
          data={data?.employees}
          isLoading={isLoading}
          setIsOpen={setIsOpen}
        />
      </div>
      <Pagination
        style={{ paddingLeft: '1.8vw', paddingTop: '1vw' }}
        current={typeof query.page === 'number' ? query.page : parseInt(query.page)}
        defaultPageSize={6}
        showSizeChanger={false}
        total={data?.totalElements}
        onChange={(page) => {
          push('page', page)
        }}
      />
      <EditModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  )
}

export default CompanyEmployees
