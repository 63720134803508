import React from "react";
import Sidebar from "./Sidebar";
import Content from "./Content";
import {useRouteMatch} from "react-router-dom";

const Developer = () => {
  let {path} = useRouteMatch();
  return (
    <div style={{
      width: '100vw',
      height: '100vh',
      display: 'flex'
    }}>
      <div style={{
        width: '15vw'
      }}>
        <Sidebar/>
      </div>
      <div style={{
        width: '85vw'
      }}>
        <Content path={path}/>
      </div>
    </div>
  )
}

export default Developer;
