import { LoadingOutlined } from '@ant-design/icons'
import { Checkbox, DatePicker, Form, Input, Modal, Spin } from 'antd'
import moment from 'moment'
import React, { useEffect } from 'react'
import ReactInputMask from 'react-input-mask'
import { useDispatch, useSelector } from 'react-redux'
import { useUpdateClient } from '../../queries/mutations'
import { useDetailedClient } from '../../queries/queries'
import {
  addClientPhone,
  checkClientPhone,
  clearClientData,
  delateClientPhone,
  newClientPhones,
} from '../../redux/actions'
import { contractTypes } from '../../utils/constants/contractTypes'

const UpdateClientModal = ({ visible, closeModal, contractType, clientId }) => {
  const { Item } = Form
  const [formInstance] = Form.useForm()
  const dispatch = useDispatch()
  const clientPhones = useSelector((state) => state.clientCont.clientPhones)
  const { data, isLoading } = useDetailedClient(clientId)
  const updateClientMutation = useUpdateClient(
    contractType,
    clientId,
    closeModal
  )

  // initial values
  useEffect(() => {
    if (data && clientPhones[0]?.phone === null) {
      dispatch(newClientPhones(data?.clientPhones))

      let fieldObj = {}
      if (Array.isArray(data?.clientPhones)) {
        data?.clientPhones?.forEach((phn, index) => {
          fieldObj = { ...fieldObj, [`phones${index}`]: phn.phone }
        })
      } else if (data[clientPhones]) {
        fieldObj = { ...fieldObj, [clientPhones]: data[clientPhones] }
      }

      formInstance.setFieldsValue({
        ...fieldObj,
        fullName: data?.fullName,
        address: data?.address,
        passportData: data?.passportData,
        passportDate: data?.passportDate
          ? moment(data?.passportDate, 'DD.MM.YYYY')
          : null,
        passportPlace: data?.passportPlace,
      })
    }
  }, [data, dispatch, clientPhones, formInstance])

  // for setFieldsValue after removing phone number
  useEffect(() => {
    let fieldObj = {}
    if (Array.isArray(clientPhones)) {
      clientPhones?.forEach((phn, index) => {
        fieldObj = { ...fieldObj, [`phones${index}`]: phn.phone }
      })
    }
    formInstance.setFieldsValue({
      ...fieldObj,
    })
  }, [clientPhones, formInstance])

  // on finish
  const onFinish = (val) => {
    let phonesArr = []
    let updateObj = {}
    let i = 0
    Object.keys(val).forEach((field) => {
      if (field.includes('phones')) {
        phonesArr.push({
          phone: val[field],
          active: clientPhones[i].active,
        })
        i++
      } else {
        updateObj = {
          ...updateObj,
          [field]: val[field],
        }
      }
    })
    updateObj = {
      ...updateObj,
      clientPhones: phonesArr,
      passportDate: moment(val.passportDate).format('DD.MM.YYYY'),
    }

    updateClientMutation.mutate(updateObj)
  }

  // after close modal
  const afterClose = () => {
    formInstance.resetFields()
    dispatch(clearClientData())
  }

  // remove phone number
  const removePhoneNumber = async (index) => {
    await dispatch(delateClientPhone(index))
    formInstance.setFieldsValue({ [`phones${index}`]: '' })
  }

  return (
    <Modal
      centered
      visible={visible}
      onCancel={closeModal}
      afterClose={afterClose}
      okText="Tahrirlash"
      cancelText="Bekor qilish"
      title={
        <span>
          Mijoz ma'lumotlarini tahrirlash{' '}
          {isLoading && (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 12, marginLeft: '10px', color: '#5f5e5e' }}
                  spin
                />
              }
            />
          )}
        </span>
      }
      okButtonProps={{
        onClick: () => formInstance.submit(),
        loading: updateClientMutation.isLoading,
      }}
    >
      <Form
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        form={formInstance}
      >
        <label>
          <span>F.I.O</span>
          <Item
            name="fullName"
            rules={[
              {
                required: true,
                message: 'Ism kiritilmagan!',
              },
            ]}
          >
            <Input />
          </Item>
        </label>
        <label>
          <span>Manzili</span>
          <Item
            name="address"
            rules={[
              {
                required: true,
                message: 'Manzil kiritilmagan!',
              },
            ]}
          >
            <Input />
          </Item>
        </label>
        <div>
          <span>Telefon raqami</span>
          {clientPhones.map((phone, index) => (
            <div key={index} className="df jc-sb">
              <Form.Item
                name={'phones' + index + ''}
                rules={[
                  {
                    required: true,
                    message: 'Telefon raqam kiritilmagan!',
                    len: 13,
                  },
                ]}
              >
                <ReactInputMask
                  style={{
                    width: '350px',
                  }}
                  mask="+999999999999"
                  maskChar=""
                  id="Phone"
                >
                  {(inputProps) => <Input {...inputProps} />}
                </ReactInputMask>
              </Form.Item>
              <Checkbox
                checked={phone.active}
                onChange={() => {
                  dispatch(checkClientPhone(index))
                }}
                style={{
                  marginLeft: index === 0 ? '15px' : '18px',
                  display: 'inline-block',
                }}
              />
              {index === 0 ? (
                <>
                  {clientPhones.length !== 1 && (
                    <span
                      className="YuridikRemoveButton"
                      onClick={() => removePhoneNumber(index)}
                    >
                      <span
                        style={{
                          position: 'absolute',
                          display: 'block',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%,-55%)',
                        }}
                      >
                        -
                      </span>
                    </span>
                  )}
                  <span
                    className="YuridikAddButton"
                    onClick={() => {
                      dispatch(addClientPhone())
                    }}
                  >
                    <span
                      style={{
                        position: 'absolute',
                        display: 'inline-block',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-55%)',
                      }}
                    >
                      +
                    </span>
                  </span>
                </>
              ) : (
                <>
                  <span
                    className="YuridikRemoveButton"
                    onClick={() => removePhoneNumber(index)}
                  >
                    <span
                      style={{
                        position: 'absolute',
                        display: 'inline-block',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-55%)',
                      }}
                    >
                      -
                    </span>
                  </span>
                  <span className="YuridikAddButton bd-n">
                    <span
                      style={{
                        position: 'absolute',
                        display: 'inline-block',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-55%)',
                      }}
                    />
                  </span>
                </>
              )}
            </div>
          ))}
        </div>
        {contractType === contractTypes.SIMPLE && (
          <>
            <label>
              <span>Pasport seriyasi va raqami</span>
              <Item
                name="passportData"
                rules={[
                  {
                    required: true,
                    message: 'Pasport seriyasi va raqamini kiriting!',
                    len: 9,
                  },
                ]}
              >
                <ReactInputMask
                  mask="aa9999999"
                  maskChar=""
                  onChange={(e) => {
                    formInstance.setFieldsValue({
                      passportData: e.target.value.toUpperCase(),
                    })
                  }}
                  id="passportData"
                >
                  {(inputProps) => <Input {...inputProps} />}
                </ReactInputMask>
              </Item>
            </label>
            <label>
              <span>Pasport berilgan sana</span>
              <Item
                name="passportDate"
                rules={[
                  {
                    required: true,
                    message: 'Pasport berilgan sanani kiriting!',
                  },
                ]}
              >
                <DatePicker format="DD.MM.YYYY" style={{ width: '100%' }} />
              </Item>
            </label>
            <label>
              <span>Pasport berilgan joy</span>
              <Item
                name="passportPlace"
                rules={[
                  {
                    required: true,
                    message: 'Berilgan joyni kiriting!',
                  },
                ]}
              >
                <Input />
              </Item>
            </label>
          </>
        )}
      </Form>
    </Modal>
  )
}

export default UpdateClientModal
