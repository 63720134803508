import { Button, Table, Tag, Typography } from 'antd'
import React from 'react'
import { BiEditAlt } from 'react-icons/all'
import { useQueryString } from '../../../hooks/useQueryString'

const { Column } = Table

const TableCompanies = ({ data, openModal, spinner }) => {
  // const { push, location } = useHistory()
  const { push, query } = useQueryString()
  return (
    <>
      <Typography>Kompaniyalar</Typography>
      <Table
        rowKey="id"
        loading={spinner}
        showSizeChanger={true}
        size={'middle'}
        pagination={false}
        dataSource={data}
        style={{ width: '100%', marginTop: '30px', paddingBottom: '3px' }}
        rowClassName="editable-row"
      >
        <Column
          title="#"
          dataIndex="id"
          key="id"
          render={(_, __, index) =>
            (parseInt(query.page || '1') - 1) * 6 + index + 1
          }
        />
        <Column title="Kompaniya" dataIndex="name" key="fullName" />
        <Column title="Telefon" dataIndex="phone" key="role" />
        <Column
          title="Status"
          dataIndex="status"
          key="role"
          render={(status) => (
            <span>
              {status ? (
                <Tag color="#87d068">active</Tag>
              ) : (
                <Tag color="#f50">deaktiv</Tag>
              )}
            </span>
          )}
        />
        <Column title="Director" dataIndex="director" key="companyName" />
        <Column
          title="Actions"
          key="actions"
          render={(data) => (
            <Button
              onClick={() => {
                openModal()
                push('id', data.id)
              }}
              className={'developerTableCompanyActionButtons'}
              type="primary"
              icon={<BiEditAlt />}
            />
          )}
        />
      </Table>
    </>
  )
}

export default TableCompanies
