import React, {useState} from "react";
import CompanyEmployees from "./companyEmployees/companyEmployees";
import Companies from "./companies/Companies";
import RouteByRole from "../RouteByRole";
import {Input} from "antd";
import {BiSearchAlt} from "react-icons/all";

const Content = ({path}) => {
  const [searchValue, setSearchValue] = useState(null);
  return (
    <>
      <Input
        placeholder={"qidirish"}
        prefix={<BiSearchAlt style={{marginLeft: '1vw', marginRight: "0.5vw"}}/>}
        className={"developerSearchInput"}
        onChange={(e) => setSearchValue(e.target.value)}/>
      <RouteByRole path={path} page="/companyEmployees">
        <CompanyEmployees searchValue={searchValue}/>
      </RouteByRole>
      <RouteByRole path={path} page="/company">
        <Companies searchValue={searchValue}/>
      </RouteByRole>
    </>
  )
}

export default Content
