import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons'

import { Button, Popover } from 'antd'

function SidebarMenuButton(props) {
  let buttonClass = 'sidebarMenuButton'
  let iconClass = 'smbuttonIcon'

  let plusButton = null
  let plusButtonClass = 'smPlusButton'

  let location = useLocation()

  if (location.pathname === props?.url) {
    buttonClass += ' sidebarMenuButtonActive'
    iconClass += ' smbuttonIconActive'
  }

  if (location.pathname === props?.plusUrl) {
    plusButtonClass += ' smPlusButtonActive'
  }

  if (props?.plusUrl) {
    if (props?.plusUrlSpecial) {
      plusButton = (
        <Popover
          placement="right"
          content={
            <div className="popoverLinks">
              <Link to={props?.plusUrl}>Oddiy shartnoma</Link>
              <br />
              <Link to={props?.plusUrlSpecial}>Maxsus shartnoma</Link>
            </div>
          }
        >
          <Button icon={<PlusOutlined />} className={plusButtonClass} />
        </Popover>
      )
    } else {
      plusButton = (
        <Link to={props?.plusUrl}>
          <Button icon={<PlusOutlined />} className={plusButtonClass} />
        </Link>
      )
    }
  }

  return (
    <div style={{ position: 'relative', marginBottom: 10, marginTop: 10 }}>
      <Link to={props?.url}>
        <Button type="primary" className={buttonClass}>
          <span className={iconClass}>{props?.icon}</span> {props?.name}
        </Button>
      </Link>
      {plusButton}
    </div>
  )
}

export default SidebarMenuButton
