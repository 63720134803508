import {
  CONTRACT_OPEN_PAGE,
  CLIENT_DATA,
  PAYMENT_DATA,
  CLIENT_PHONES,
  PAYMENT_TABLE,
  PAYMENT_FIRST_DATE,
  PAYMENT_DELAY,
  PAYMENT_RANGE,
  PAYMENT_RESIDUE,
  PAYMENT_CLEAR,
  CLIENT_CLEAR,
  PAYMENT_TABLE_COUNT,
  CLIENT_RESTORE,
  PRODUCT_LIST,
  CONTRACT_DATE,
  CONTRACT_SAVE,
  SET_PRODUCT_LIST,
  ADD_SPONSOR,
  UPDATE_SPONSOR,
  DELETE_SPONSOR,
  CLEAR_SPONSOR,
  SET_PROJECT_VERSION,
} from './types'
import moment from 'moment'

//////// CLIENT ACtIONS
export const clientClear = () => {
  return {
    type: CLIENT_CLEAR,
    payload: null,
  }
}

export const clientRestore = (data) => {
  return {
    type: CLIENT_RESTORE,
    payload: data,
  }
}

export const setClientData = (data) => {
  return {
    type: CLIENT_DATA,
    payload: data,
  }
}

export const addClientPhone = () => {
  return (dispatch, getState) => {
    const clientPhones = [...getState().clientCont.clientPhones]
    clientPhones.push({
      phone: null,
      active: false,
    })
    dispatch(newClientPhones(clientPhones))
  }
}

export const delateClientPhone = (i) => {
  return async (dispatch, getState) => {
    const clientPhones = [...getState().clientCont.clientPhones]
    const newPhones = clientPhones.filter((phone, index) => {
      return index !== i
    })
    dispatch(newClientPhones(newPhones))
  }
}

export const newClientPhones = (phones) => {
  return {
    type: CLIENT_PHONES,
    payload: phones,
  }
}

export const checkClientPhone = (i) => {
  return (dispatch, getState) => {
    const clientPhones = [...getState().clientCont.clientPhones]
    let newClientsPhone = []
    clientPhones.forEach((phn, index) => {
      let newPhone
      if (phn.id) {
        if (index === i) {
          newPhone = {
            phone: phn.phone,
            active: true,
            id: phn.id,
          }
        } else {
          newPhone = {
            phone: phn.phone,
            active: false,
            id: phn.id,
          }
        }
      } else {
        if (index === i) {
          newPhone = {
            phone: phn.phone,
            active: true,
          }
        } else {
          newPhone = {
            phone: phn.phone,
            active: false,
          }
        }
      }
      newClientsPhone.push(newPhone)
    })
    dispatch(newClientPhones(newClientsPhone))
  }
}

export const clearClientData = () => {
  return (dispatch) => {
    dispatch(
      newClientPhones([
        {
          phone: null,
          active: false,
        },
      ])
    )
    dispatch(setClientData(null))
  }
}

//////////////// Payment
export const setPaymentData = (data) => {
  return (dispatch) => {
    dispatch({
      type: PAYMENT_DATA,
      payload: data,
    })
  }
}

export const setTableCount = (count) => {
  return {
    type: PAYMENT_TABLE_COUNT,
    payload: count,
  }
}

export const createPaymentTable = (data) => {
  return {
    type: PAYMENT_TABLE,
    payload: data,
  }
}

export const setFirstDate = (data) => {
  return {
    type: PAYMENT_FIRST_DATE,
    payload: data,
  }
}

export const setRange = (data) => {
  return {
    type: PAYMENT_RANGE,
    payload: data,
  }
}

export const setDelay = (data) => {
  return {
    type: PAYMENT_DELAY,
    payload: data,
  }
}

export const setResidue = (data) => {
  return {
    type: PAYMENT_RESIDUE,
    payload: data,
  }
}

export const clearPayments = () => {
  return {
    type: PAYMENT_CLEAR,
    payload: null,
  }
}

/////////////// contract
export const contractPage = (page) => {
  return {
    type: CONTRACT_OPEN_PAGE,
    payload: page,
  }
}

export const clearAllReducer = () => {
  return (dispatch) => {
    dispatch(clientClear())
    dispatch(contractPage(1))
    dispatch(contractDate(moment(new Date()).format('DD.MM.YYYY HH:mm:ss')))
    dispatch(clearPayments())
    dispatch(productAdd([], 1, true))
    dispatch(clearSponsorList())
  }
}

export const contractDate = (date) => {
  return {
    type: CONTRACT_DATE,
    payload: date,
  }
}

export const contractSave = (contract) => {
  return {
    type: CONTRACT_SAVE,
    payload: contract,
  }
}

//// product action

export const productAdd = (product, index, clear) => {
  return (dispatch, getState) => {
    if (!clear) {
      let productList = [...getState().productCont?.productList]
      if (index !== 'new') {
        productList[index] = product
      } else {
        productList.push(product)
      }
      dispatch({
        type: PRODUCT_LIST,
        payload: productList,
      })
    } else {
      dispatch({
        type: PRODUCT_LIST,
        payload: [],
      })
    }
  }
}

export const setProducts = (products) => {
  return {
    type: SET_PRODUCT_LIST,
    payload: products,
  }
}

export const productDelete = (index) => {
  return (dispatch, getState) => {
    let productList = [...getState().productCont?.productList]
    productList.splice(index, 1)
    dispatch({
      type: PRODUCT_LIST,
      payload: productList,
    })
  }
}

/////////// sponsor actions
export const addSponsor = (sponsor) => {
  return {
    type: ADD_SPONSOR,
    payload: sponsor,
  }
}

export const updateSponsor = (sponsor, index) => {
  return {
    type: UPDATE_SPONSOR,
    payload: { sponsor, index },
  }
}

export const deleteSponsor = (index) => {
  return {
    type: DELETE_SPONSOR,
    payload: index,
  }
}

export const clearSponsorList = () => {
  return {
    type: CLEAR_SPONSOR,
  }
}

export const setProjectVersion = (version) => {
  return {
    type: SET_PROJECT_VERSION,
    payload: version,
  }
}
