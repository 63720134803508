import React from 'react'
import noImg from '../../../images/avatar.png'

const ImgEmployee = () => {
  return (
    <img
      style={{width: '40px', height: '40px'}}
      alt="employeeImg"
      src={noImg}/>
  )
}


export default ImgEmployee