import React, { useEffect, useState } from 'react'
import usd_flag from '../../../images/usd_flag.png'
import { Button, Input, Switch } from 'antd'
import { useGetCurrency } from '../../../queries/queries'
import { customCurrency } from '../../../utils/helper/customCurrency'
import {
  useCurrencyEdit,
  useCurrencyGetAutoRenew,
} from '../../../queries/mutations'
import { parseLocaledString } from '../../../utils/helper/parseLocaledString'
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons'

const CurrencyConfig = () => {
  const { data } = useGetCurrency()
  const currencyEdit = useCurrencyEdit()
  const getAutoRenow = useCurrencyGetAutoRenew()
  const [value, setValue] = useState('')
  const [autoRenew, setAutoRenew] = useState(false)
  const handleSave = () => {
    currencyEdit.mutate({
      ...data,
      exchange: parseLocaledString(value),
      autoRenew: autoRenew,
    })
  }

  useEffect(() => {
    if (data?.exchange) {
      setValue(customCurrency(String(data?.exchange)))
      setAutoRenew(data?.autoRenew)
    }
  }, [data])
  return (
    <div
      style={{
        background: '#FFFFFF',
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.05)',
        borderRadius: 6,
        padding: 24,
      }}
    >
      <label>
        Valyuta{' '}
        {getAutoRenow.isLoading ? (
          <LoadingOutlined />
        ) : (
          <ReloadOutlined
            style={{ cursor: 'pointer' }}
            onClick={() => getAutoRenow.mutate()}
          />
        )}
      </label>
      <br />
      <br />
      <div className="currencyConfig_item">
        <div>
          <img src={usd_flag} alt="" />
          USD - AQSh dollari
        </div>
        <Input
          className="currencyConfig_item_value"
          suffix={'UZS'}
          value={value}
          onChange={(e) => setValue(customCurrency(e.target.value))}
        />
        <Switch checked={autoRenew} onChange={setAutoRenew} />
        <Button
          type="primary"
          onClick={handleSave}
          loading={currencyEdit.isLoading}
        >
          Saqlash
        </Button>
      </div>
    </div>
  )
}

export default CurrencyConfig
