import React, { useState } from 'react'
import styles from './broker.module.scss'
import { Button, Input, Layout, Pagination, Space, Table } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useBrokerAll } from './services/queries'
import CreateBroker from './components/create-broker/CreateBroker'
import queryString from 'query-string'

const { Column } = Table

const { Content } = Layout

const Broker = () => {
  const [search, setSearch] = useState({
    name: '',
    page: '',
    size: 10,
  })
  const [createBroker, setCreateBroker] = useState({ id: null, visible: false })

  const { data, isLoading, isFetching } = useBrokerAll(
    '?' + queryString.stringify(search)
  )

  const handleCreate = (id) => {
    setCreateBroker({ id, visible: true })
  }
  return (
    <div className={styles.container}>
      <Content
        style={{
          padding: '10px 20px 0 20px',
          overflow: 'initial',
          backgroundColor: '#FAFBFD',
        }}
      >
        <Space
          direction="horizontal"
          style={{
            width: '100%',
            padding: '20px 20px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <Input
              className="circle-input"
              style={{ width: 400, height: '38px' }}
              placeholder="Tezkor qidiruv..."
              onChange={(e) => {
                setSearch((prev) => ({
                  ...prev,
                  name: e.target.value,
                }))
              }}
              prefix={<SearchOutlined className="site-form-item-icon" />}
            />
          </div>
          <Button onClick={() => handleCreate(null)}>Vositachi yaratish</Button>
        </Space>
      </Content>
      <Table
        isFetching={isFetching}
        dataSource={data?.brokers}
        spinner={isLoading}
        onRow={(record) => {
          return {
            onClick: () => {
              setCreateBroker({
                id: record?.id,
                visible: true,
                name: record?.name,
                phone: record?.phone,
              })
            },
          }
        }}
        pagination={false}
      >
        <Column title="Vositachi" key="name" dataIndex={'name'} />
        <Column title="Telefon raqam" key="phone" dataIndex={'phone'} />
      </Table>
      <Pagination
        current={data?.currentPage}
        pageSize={'10'}
        showSizeChanger={false}
        total={data?.totalElements}
        onChange={(e) => {
          setSearch((prev) => ({ ...prev, page: e }))
        }}
      />
      <CreateBroker
        setCreateBroker={setCreateBroker}
        createBroker={createBroker}
      />
    </div>
  )
}

export default Broker
