import {
  CLIENT_DATA,
  CLIENT_PHONES,
  CLIENT_CLEAR,
  CLIENT_RESTORE
} from './types'

const initialState = {
  clientData: null,
  clientPhones: [{
    phone: null,
    active: true
  }],
}

const clientReducer = (state = initialState, action) => {
  const {type, payload} = action

  switch (type) {
    case CLIENT_PHONES:
      return {
        ...state,
        clientPhones: payload,
      }
    case CLIENT_DATA:
      return {
        ...state,
        clientData: payload,
      }
    case CLIENT_CLEAR:
      return initialState
    case CLIENT_RESTORE:
      return payload
    default:
      return state
  }
}

export default clientReducer
