import { Button, Pagination, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { CgMathPlus } from 'react-icons/all'
import { useQueryString } from '../../../hooks/useQueryString'
import { apis } from '../../../http/apis'
import { useBuilderCompany } from '../../../queries/queries'
import EditModal from './EditModal'
import TableCompanies from './TableCompanies'

const Companies = () => {
  // TODO make pagination
  const [openEditModal, setOpenEditModal] = useState(false)
  const { data, isLoading } = useBuilderCompany(apis.BUILDING_COMPANY)
  const { push, query } = useQueryString()
  useEffect(() => {
    push('page', 1)
    //eslint-disable-next-line
  }, [])
  return (
    <div>
      <div className={'dashboardContentsTitleCont'}>
        <Typography>Kompaniya qo‘shish</Typography>
        <Button
          onClick={() => setOpenEditModal(true)}
          className={'dashboardContentPlusIcon'}
          shape="circle"
          icon={<CgMathPlus />}
        />
      </div>
      <div className={'dashboardContentsTableCon'}>
        <TableCompanies
          spinner={isLoading}
          data={data?.companies}
          openModal={() => setOpenEditModal(true)}
        />
      </div>
      <Pagination
        style={{ paddingLeft: '1.8vw', paddingTop: '1vw' }}
        current={parseInt(query.page)}
        defaultPageSize={6}
        total={data?.totalElements}
        //TODO make pagination
        onChange={(page) => push('page', page)}
      />
      <EditModal open={openEditModal} close={() => setOpenEditModal(false)} />
    </div>
  )
}

export default Companies
