import { useInfiniteQuery, useQuery } from 'react-query'
import $api from '../../../http/$api'
import { queryKeys } from '../utils/constants/queryKeys'
import { endPoints } from '../utils/constants/endPoints'
import queryString from 'query-string'

export function useBlackListAll(search = '') {
  return useQuery([queryKeys.BLACKLIST, search], async () => {
    const res = await $api.get(endPoints.BLACKLIST + search)
    return res?.data?.data
  })
}

export function useBlackListSame(
  search = {
    page: 1,
    size: 10,
    fullName: '',
    phone: '',
  }
) {
  const searchCopy = queryString.stringify({
    page: 1,
    size: 10,
    ...search,
  })
  return useInfiniteQuery(
    [queryKeys.BLACKLIST_SAME, searchCopy],
    async ({ pageParam = 1 }) => {
      const res = await $api.get(
        endPoints.BLACKLIST_SAME + `?page=${pageParam}&` + searchCopy
      )
      return res?.data?.data
    },
    {
      getNextPageParam: (lastPage, page) => {
        if (page.length < lastPage?.totalPages) {
          return page.length + 1
        } else {
          return undefined
        }
      },
      enabled: !!search.fullName && !!search.phone,
    }
  )
}
