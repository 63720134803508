import { LoadingOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Tag,
  Typography,
} from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { AiFillPrinter } from 'react-icons/ai'
import { BiMessageCheck } from 'react-icons/bi'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {
  useDownloadContract,
  useDownloadMonthlyPaymentMutation,
  useDownloadPaymentMutation,
  useDownloadPaymentsExcel,
  usePaymentMutation,
} from '../../queries/mutations'
import { useDetailedClient, useOneContractInfo } from '../../queries/queries'
import { queryNames } from '../../queries/queryNames'
import { contractSave } from '../../redux/actions'
import CountGraphTable from '../contract/CountGraphTable'
import ProductsList from '../contract/ProductsList'
import TableNextPayment from '../paymentPlus/TableNextPayment'

const DetailedClient = () => {
  const params = useParams()
  const { Title } = Typography
  const history = useHistory()
  const { Option } = Select

  const [monthlyPaymentsTableModal, setMonthlyPaymentsTableModal] =
    useState(false)
  const [payType, setPayType] = useState('')

  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const { data: clientData } = useDetailedClient(params?.id)
  const {
    data: contractData,
    isFetching: contractIsFetching,
    isLoading: contractIsLoading,
  } = useOneContractInfo(params?.id)
  const downloadContract = useDownloadContract()
  const downloadMonthlyPayments = useDownloadMonthlyPaymentMutation()
  const downloadWordOnePayment = useDownloadPaymentMutation()
  const successPayment = () => {
    queryClient.invalidateQueries([
      queryNames.CONTRACTS,
      queryNames.INFO,
      contractData?.contract?.id,
    ])
  }
  const paymentCreate = usePaymentMutation(
    successPayment,
    false,
    downloadWordOnePayment
  )
  const downloadPayments = useDownloadPaymentsExcel()

  // client name
  const clientName = () => {
    if (clientData?.fullName.length > 35) {
      return clientData?.fullName?.slice(0, 35) + ' ...'
    } else return clientData?.fullName
  }

  // go to payment page
  const goToPaymentPlusPageFunc = () => {
    dispatch(contractSave(contractData?.contract))
    history.push('/main/paymentPlus')
  }

  // pay first payment
  const payFirstPaymentFunc = () => {
    if (!payType) {
      message.error("To'lov turi tanlanmagan")
    } else {
      paymentCreate.mutate({
        id: contractData?.contarct?.id,
        paymentBody: {
          amount:
            contractData?.contract?.firstPayment -
            contractData?.contract?.payed,
          type: payType,
          createdDate: moment(new Date()).format('DD.MM.YYYY HH:mm:ss'),
        },
      })
    }
  }

  return (
    <div>
      <div className="df">
        <Title
          style={{
            fontFamily: 'IBM Plex Sans',
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '24px',
            lineHeight: '100%',
            color: '#989FB9',
            marginTop: '30px',
            marginBottom: '30px',
            cursor: 'default',
          }}
        >
          Mijoz : {clientData?.fullName}
          {contractIsFetching && (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 12, marginLeft: '10px', color: '#5f5e5e' }}
                  spin
                />
              }
            />
          )}
        </Title>
      </div>
      <Row>
        <Col span={12}>
          <div className="contractInfoClientCard">
            <div className="df">
              <span className="oneContractLabel">
                <span className={'oneContractLabelName'}>F.I.SH:</span>
                <span title={clientData?.fullName}>{clientName()}</span>
              </span>
            </div>
            <div className="df">
              <span className="oneContractLabel">
                <span className={'oneContractLabelName'}>Tel.raqam:</span>
                <div>
                  {clientData?.clientPhones?.map((phn) => (
                    <div
                      style={{
                        display: 'block',
                        marginLeft: '180px',
                      }}
                    >
                      {phn.phone}
                      {phn.active && (
                        <BiMessageCheck
                          style={{
                            color: 'green',
                            paddingLeft: '5px',
                            fontSize: '18px',
                          }}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </span>
            </div>
            {contractData?.contract?.type === 'SIMPLE' && (
              <div className="df">
                <span className="oneContractLabel">
                  <span className={'oneContractLabelName'}>
                    Passport seriyasi:
                  </span>
                  {clientData?.passportData}
                </span>
              </div>
            )}
            {contractData?.contract?.type === 'SIMPLE' && (
              <div className="df">
                <span className="oneContractLabel">
                  <span className={'oneContractLabelName'}>berilgan sana:</span>
                  {clientData?.passportDate}
                </span>
              </div>
            )}
            {contractData?.contract?.type === 'SIMPLE' && (
              <div className="df">
                <span className="oneContractLabel">
                  <span className={'oneContractLabelName'}>olingan joy:</span>
                  {clientData?.passportPlace}
                </span>
              </div>
            )}
            <div className="df">
              <span className="oneContractLabel">
                <span className={'oneContractLabelName'}>Manzil:</span>
                <span title={clientData?.address}>
                  {clientData?.address.length > 35
                    ? clientData?.address?.slice(0, 35) + ' ...'
                    : clientData?.address}
                </span>
              </span>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            <div
              title={'Word shartnoma yuklab olish'}
              className="contractInfoPDFButton"
              onClick={() => {
                downloadContract.mutate(contractData?.contract?.id)
              }}
            >
              {downloadContract.isLoading ? (
                <Spin
                  style={{
                    display: 'flex',
                    margin: '0 auto',
                  }}
                />
              ) : (
                <span>word-shartnoma</span>
              )}
            </div>
            {contractData?.contract?.amount !==
              contractData?.contract?.firstPayment && (
              <div
                title={"To'lov grafigini ko'rish"}
                className="contractInfoCountGButton"
                onClick={() => {
                  setMonthlyPaymentsTableModal(true)
                }}
              >
                <span>To'lov grafigi</span>
              </div>
            )}
            <div
              title={"To'lov sahifasiga o'tish"}
              style={{ marginLeft: '18px' }}
              className="contractInfoCountGButton"
              onClick={goToPaymentPlusPageFunc}
            >
              <span>To'lov sahifasi</span>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className="contractInfoContractDate">
            <div className="df">
              <span className="oneContractLabel">
                <span className={'oneContractLabelName'}>
                  Shartnoma sanasi:
                </span>
                {contractData?.contract?.createdDate}
              </span>
            </div>
            {contractData?.contract?.type === 'SPECIFIC' && (
              <div className="df">
                <span className="oneContractLabel">
                  <span className={'oneContractLabelName'}>Tugash sanasi:</span>
                  {contractData?.contract?.endDate}
                </span>
              </div>
            )}
            <div className="df">
              <span className="oneContractLabel">
                <span className={'oneContractLabelName'}>To'lov muddati:</span>
                <Tag color="#EF78F1" style={{ fontSize: 16 }}>
                  {contractData?.contract?.delay}
                </Tag>
                oy
              </span>
            </div>
            <div className="df">
              <span className="oneContractLabel">
                <span className={'oneContractLabelName'}>
                  Shartnoma summasi:
                </span>
                <Tag color="#f50" style={{ fontSize: 16 }}>
                  {contractData?.contract?.amount?.toLocaleString('ru')}
                </Tag>
              </span>
            </div>
            {contractData?.contract?.type === 'SIMPLE' && (
              <div className="df">
                <span className="oneContractLabel">
                  <span className={'oneContractLabelName'}>
                    Birinchi to'lov:
                  </span>
                  <Tag color="#F3B605" style={{ fontSize: 16 }}>
                    {contractData?.contract?.firstPayment?.toLocaleString('ru')}
                  </Tag>
                </span>
              </div>
            )}
            <div className="df">
              <span className="oneContractLabel">
                <span className={'oneContractLabelName'}>To'langan summa:</span>
                <Tag color="#108ee9" style={{ fontSize: 16 }}>
                  {contractData?.contract?.payed?.toLocaleString('ru')}
                </Tag>
              </span>
            </div>
            <div className="df">
              <span className="oneContractLabel">
                <span className={'oneContractLabelName'}>Qolgan summa:</span>
                <Tag color="#E65C62" style={{ fontSize: 16 }}>
                  {contractData?.contract?.residue?.toLocaleString('ru')}
                </Tag>
              </span>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              marginTop: '20px',
              alignItems: 'center',
            }}
          >
            {contractData?.contract?.firstPayment -
              contractData?.contract?.payed >
              0 && (
              <>
                <div>
                  <p
                    style={{
                      fontFamily: 'IBM Plex Sans',
                      fontStyle: 'normal',
                      fontWeight: 'bold',
                      fontSize: '12px',
                      textAlign: 'right',
                      color: '#FF542F',
                      margin: 0,
                    }}
                  >
                    Boshlang’ich to’lov qoldig’i
                  </p>
                  <Tag
                    color="#FF542F"
                    style={{
                      fontSize: 14,
                      display: 'block',
                      marginLeft: 'auto',
                      textAlign: 'right',
                      margin: '0',
                    }}
                  >
                    {(
                      contractData?.contract?.firstPayment -
                      contractData?.contract?.payed
                    ).toLocaleString('ru')}
                  </Tag>
                </div>
                <Select
                  allowClear
                  style={{ width: '20%', margin: '0 10px' }}
                  placeholder="To`lov turi"
                  onChange={(e) => {
                    setPayType(e)
                  }}
                >
                  <Option key={'CASH'} value={'CASH'}>
                    {'Naqd'}
                  </Option>
                  <Option key={'TRANSFER'} value={'TRANSFER'}>
                    {'P2P'}
                  </Option>
                  <Option key={'BANK'} value={'BANK'}>
                    {'Bank'}
                  </Option>
                  <Option key={'CARD'} value={'CARD'}>
                    {'Karta'}
                  </Option>
                </Select>
                <div
                  title={"Boshlang'ich to'lov qoldig'ini to'lash"}
                  className="contractInfoPDFButton"
                  style={{ marginTop: '0' }}
                  onClick={() => {
                    if (!paymentCreate.isLoading) payFirstPaymentFunc()
                  }}
                >
                  {paymentCreate.isLoading ? (
                    <Spin
                      style={{
                        display: 'flex',
                        margin: '0 auto',
                      }}
                    />
                  ) : (
                    <span>To'lov qilish</span>
                  )}
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>
      <Row style={{ width: '97%' }} gutter={[16, 0]}>
        <Col span={12}>
          <Title
            style={{
              display: 'flex',
              alignItems: 'center',
              fontFamily: 'IBM Plex Sans',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '24px',
              lineHeight: '100%',
              color: '#989FB9',
              marginTop: '30px',
              marginLeft: '30px',
              marginBottom: '30px',
              cursor: 'default',
            }}
          >
            To'lov tarixi
            <span
              onClick={() => {
                downloadPayments.mutate(contractData?.contract?.id)
              }}
              className="ContractInfoPrinterIconDiv"
            >
              {downloadPayments.isLoading ? (
                <Spin className="ContractInfoPrinterIcon" />
              ) : (
                <AiFillPrinter className="ContractInfoPrinterIcon" />
              )}
            </span>
          </Title>
          <CountGraphTable dataForTable={contractData?.historyPayments} />
        </Col>
        <Col span={12}>
          <Title
            style={{
              fontFamily: 'IBM Plex Sans',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '24px',
              lineHeight: '100%',
              color: '#989FB9',
              marginTop: '45px',
              marginBottom: '30px',
              cursor: 'default',
            }}
          >
            Shartnoma {contractData?.contract?.number} mahsulotlari
          </Title>
          <ProductsList data={contractData?.products} />
        </Col>
      </Row>
      <Modal
        title="To'lov jadvali"
        visible={monthlyPaymentsTableModal}
        onCancel={() => setMonthlyPaymentsTableModal(false)}
        footer={[
          <Button
            loading={downloadMonthlyPayments.isLoading}
            type="primary"
            onClick={() => {
              downloadMonthlyPayments.mutate(contractData?.contract?.id)
            }}
          >
            Word
          </Button>,
          <Button
            key="back"
            onClick={() => setMonthlyPaymentsTableModal(false)}
          >
            Ortga
          </Button>,
        ]}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            marginTop: '15px',
          }}
        >
          <TableNextPayment
            loading={contractIsLoading}
            isFetching={contractIsFetching}
            data={contractData?.tableMonthlyPayments}
          />
        </div>
      </Modal>
    </div>
  )
}

export default DetailedClient
