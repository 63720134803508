import {
  PAYMENT_DATA,
  PAYMENT_TABLE,
  PAYMENT_DELAY,
  PAYMENT_RANGE,
  PAYMENT_FIRST_DATE,
  PAYMENT_RESIDUE,
  PAYMENT_CLEAR,
  PAYMENT_TABLE_COUNT
} from './types'
import moment from "moment";

const initialState = {
  firstPayment: 0,
  totalPrice: 0,
  table: null,
  delay: 6,
  firstDate: moment(Date.now()).add(1, "month").format("DD.MM.YYYY"),
  range: 1,
  residue: 0,
  tableCount: 0
}

const paymentReducer = (state = initialState, action) => {
  const {type, payload} = action

  switch (type) {
    case PAYMENT_DATA:
      return {
        ...state,
        firstPayment: payload.firstPayment,
        totalPrice: payload.totalPrice
      }
    case PAYMENT_TABLE:
      return {
        ...state,
        table: payload
      }
    case PAYMENT_RANGE:
      return {
        ...state,
        range: payload
      }
    case PAYMENT_DELAY:
      return {
        ...state,
        delay: payload
      }
    case PAYMENT_FIRST_DATE:
      return {
        ...state,
        firstDate: payload
      }
    case PAYMENT_RESIDUE:
      return {
        ...state,
        residue: payload
      }
    case PAYMENT_TABLE_COUNT:
      return {
        ...state,
        tableCount: payload
      }
    case PAYMENT_CLEAR:
      return initialState
    default:
      return state
  }
}

export default paymentReducer