import React from 'react'
import { Spin, Table, Tag, Typography } from 'antd'
import NumberFormat from 'react-number-format'
import { LoadingOutlined } from '@ant-design/icons'

const { Column } = Table
const antIcon = (
  <LoadingOutlined
    style={{ fontSize: 12, marginLeft: '10px', color: '#5f5e5e' }}
    spin
  />
)

function HistoryPayment({ data, isFetching, loading, currency }) {
  return (
    <div>
      <Typography className="ClientAddHeader" style={{ paddingTop: '10px' }}>
        To'lov tarixi
      </Typography>
      <Table
        showSizeChanger={true}
        size={'middle'}
        loading={loading}
        style={{ height: '100px' }}
        dataSource={data}
        pagination={false}
        rowClassName="editable-row"
      >
        <Column
          title="№"
          key="date"
          render={(text, record, index) => {
            return <span>{index + 1}</span>
          }}
        />
        <Column
          title={() => (
            <span>
              Sana
              {isFetching && <Spin indicator={antIcon} />}
            </span>
          )}
          dataIndex="createdDate"
          key="createdDate"
        />
        <Column
          title="Summa"
          dataIndex="amount"
          key="amount"
          render={(amount,record) => (
            <>
              <NumberFormat
                value={amount}
                displayType={'text'}
                thousandSeparator={' '}
              />{' '}
              {record?.currencyType}
            </>
          )}
        />
        <Column
          title="To'lov turi"
          dataIndex="type"
          key="type"
          render={(type) => (
            <Tag
              color={
                (type === 'TRANSFER' && '#7c30d9') ||
                (type === 'CARD' && '#2ecb38') ||
                (type === 'CASH' && '#ff7825') ||
                (type === 'BANK' && '#ff5cd5')
              }
              key={type}
            >
              {(type === 'TRANSFER' && 'P2P') ||
                (type === 'CARD' && 'Karta') ||
                (type === 'CASH' && 'Naqd') ||
                (type === 'BANK' && 'Bank') ||
                type}
            </Tag>
          )}
        />
      </Table>
    </div>
  )
}

export default HistoryPayment
