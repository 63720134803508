import React, {useState} from 'react';
import MessageTable from "./MessageTable";
import {Button, Col, DatePicker, Input, Layout, Pagination, Select, Space,Row} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import {VscListFilter} from 'react-icons/vsc';
import {useMessage} from "../../queries/queries";

const {Content} = Layout;
const {RangePicker} = DatePicker;
const initialFilter = {
  name: "",
  statuses: null,
  start: null,
  finish: null,
}

const options = [
  {value: 'WILL_BE_SENT', label: 'Yuboriladi', color: '#7c30d9'},
  {value: 'IS_BEING_SENT', label: 'Yuborilmoqda', color: '#ff7825'},
  {value: 'WAS_SENT', label: "Yuborildi", color: '#2ecb38'},
  {value: 'NOT_SENT', label: 'Yuborilmagan', color: '#F60A0AFF'},
  {value: 'WRONG_MESSAGE', label: 'Xato ma\'lumot', color: '#3B0000FF'}];

function Message(){
  const [filter, setFilter] = useState (false)
  const [searchFields, setSearchFields] = useState (initialFilter);
  const [searchString, setSearchString] = useState ('?page=1&size=10');

  const {data, isLoading, isFetching} = useMessage (searchString)

  const sendRequest = (val,isEmpty) => {
    if (isEmpty) {
      setSearchString ('?page=1&size=10')
      setSearchFields (initialFilter)
    } else {
      let searchString = `?page=${val}&size=10`
      Object.keys (searchFields).forEach ((item) => {
        if (searchFields[item] && Array.isArray (searchFields[item])) {
          if (searchFields[item].length !== 0) {
            searchString = searchString + `&${item}=`
            searchFields[item].forEach ((status) => {
              searchString = searchString + status + ','
            })
          }
        } else if (searchFields[item]) {
          searchString = searchString + `&${item}=${searchFields[item]}`
        }
      })
      setSearchString (searchString)
    }
  }

  return (
    <Content style={{padding: "5px 20px 0 20px", overflow: "initial", backgroundColor: "#fcfdfa"}}>
      <Space direction="horizontal" style={{width: "100%", margin: '20px 20px', marginRight: '0'}}>
        <Input
          name="searchInfo"
          className="circle-input"
          placeholder="Tezkor qidiruv..."
          style={{width: 400, height: "38px"}}
          prefix={<SearchOutlined className="site-form-item-icon"/>}
          onChange={(e) => {
            setSearchFields ({
              ...searchFields,
              name: e.target.value
            })
          }}
          onPressEnter={() => sendRequest (1)}
        />
        <button
          type='button'
          onClick={() => {
            sendRequest (1,true)
            setFilter (!filter)
          }}
          className='ClientFiltrButton'
        >
          <VscListFilter style={{
              position: 'absolute',
              fontSize: '1.4rem',
              left: '20px',
              fontFamily: 'IBM Plex Sans',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '18px',
              letterSpacing: '0px',
            }}/>
          <span style={{
              margin: '0 0 0 25px',
              fontFamily: 'IBM Plex Sans',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '18px',
              letterSpacing: '0px',
            }}>Filtr</span>
        </button>
      </Space>
      {filter &&
      <Row style={{width: '930px', paddingLeft: '20px', marginRight: '0px', marginBottom: '20px'}}>
        <Col lg={7} style={{marginRight: '10px'}}>
          <label
            htmlFor='isSend'
            className='ClientFilterLabel'
          >Holati:</label>
          <Select
            mode="multiple"
            name="status"
            showArrow
            allowClear
            style={{width: '100%'}}
            options={options}
            onChange={(e) => {
              setSearchFields ({
                ...searchFields,
                statuses: e
              })
            }}
          />
        </Col>
        <Col lg={9}>
          <label
            htmlFor='time'
            className='ClientFilterLabel'
          >Sana:</label>
          <RangePicker
            id={"time"}
            className="ClientSelectorStatus"
            style={{width: '100%',}}
            format='DD.MM.YYYY'
            onChange={(date, dateString) => {
              setSearchFields ({
                ...searchFields,
                start: dateString[0],
                finish: dateString[1]
              })
            }}
          />
        </Col>
        <Col lg={6}>
          <Button
            type={"primary"}
            style={{
              marginLeft: '60px',
              marginTop: '30px'
            }}
            onClick={() => {
              sendRequest (1)
            }}>
            Qidirish
          </Button>
        </Col>
      </Row>}
      <MessageTable
        isFetching={isFetching}
        data={data?.sendMessages || []}
        spinner={isLoading}
        pageNumber={data?.currentPage}/>
      <Pagination
        current={data?.currentPage}
        pageSize={"10"}
        showSizeChanger={false}
        total={data?.totalElements}
        onChange={(e) =>sendRequest(e)}/>
    </Content>
  )
}

export default Message