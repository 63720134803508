import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import {
  useResendVerificationPaymeCard,
  useResendVerificationUzCard,
} from '../../queries/mutations'
import { cardTypes } from '../../utils/constants/cardTypes'

export const ResendVerificationCode = ({ cardType, session, token }) => {
  const [timer, setTimer] = useState(60)
  const [isSent, setIsSent] = useState(false)
  const resendPaymeCard = useResendVerificationPaymeCard()
  const resendUzCard = useResendVerificationUzCard()

  const onClick = async () => {
    if (token && cardType === cardTypes.PAYME) {
      await resendPaymeCard.mutateAsync(token)
      setIsSent(true)
    } else if (session && cardType === cardTypes.UZCARD) {
      await resendUzCard.mutateAsync(session)
      setIsSent(true)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1)
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [timer])

  if (isSent) {
    return <span>Tasdiqlash kodi qayta jo'natildi</span>
  }

  return (
    <Button disabled={timer > 0} type="link" onClick={onClick}>
      Qayta jo'natish {timer ? ':' + timer : ''}
    </Button>
  )
}
