import React from 'react'
import { Result } from "antd";

export default function NotAccess() {
    return (
        <div>
            <Result
                title="403"
                subTitle="Sizda bu sahifa uchun huquq yo`q!"
                icon={<img src={process.env.PUBLIC_URL + "/images/astronaut.png"} alt={"not-access"} style={{ width: 128, marginTop: 50 }} />}
                style={{ backgroundColor: "#fafbfd" }}
            />
        </div>
    )
}
