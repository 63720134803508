import React, { useState } from 'react'
import styles from './blacklist.module.scss'
import { useBlackListAll } from './services/queries'
import { Pagination, Table, Layout, Space, Input } from 'antd'
import {
  DeleteOutlined,
  LoadingOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import queryString from 'query-string'
import { useBlackListDelete } from './services/mutation'

const { Content } = Layout
const { Column } = Table

const Blacklist = () => {
  const [search, setSearch] = useState({ text: '', page: '', size: 10 })
  const { data, isLoading, isFetching } = useBlackListAll(
    '?' + queryString.stringify(search)
  )

  return (
    <div className={styles.container}>
      <Content
        style={{
          padding: '10px 20px 0 20px',
          overflow: 'initial',
          backgroundColor: '#FAFBFD',
        }}
      >
        <Space
          direction="horizontal"
          style={{
            width: '100%',
            padding: '20px 20px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <Input
              className="circle-input"
              style={{ width: 400, height: '38px' }}
              placeholder="Tezkor qidiruv..."
              onChange={(e) => {
                setSearch((prev) => ({
                  ...prev,
                  text: e.target.value,
                }))
              }}
              prefix={<SearchOutlined className="site-form-item-icon" />}
            />
          </div>
        </Space>
      </Content>
      <Table
        isFetching={isFetching}
        dataSource={data?.data}
        spinner={isLoading}
        loading={isLoading}
        pagination={false}
        size={'middle'}
      >
        <Column title="Mijoz" key="clientName" dataIndex={'clientName'} />
        <Column title="Telefon raqam" key="phone" dataIndex={'phone'} />
        <Column title="Masul" key="createdBy" dataIndex={'createdBy'} />
        <Column title="Sana" key="createdDate" dataIndex={'createdDate'} />
        <Column
          key="action"
          render={(record) => <BlacklistAction record={record} />}
        />
      </Table>
      <Pagination
        current={data?.currentPage}
        pageSize={'10'}
        showSizeChanger={false}
        total={data?.totalElements}
        onChange={(e) => {
          setSearch((prev) => ({ ...prev, page: e }))
        }}
      />
    </div>
  )
}

export default Blacklist

const BlacklistAction = ({ record }) => {
  const blackListDeleteMutate = useBlackListDelete()

  const handleDelete = () => {
    blackListDeleteMutate.mutate(record?.id)
  }

  return (
    <>
      {!(
        blackListDeleteMutate.isLoading &&
        blackListDeleteMutate.variables === record?.id
      ) ? (
        <span className="ClientTableIcon" onClick={handleDelete}>
          <DeleteOutlined />
        </span>
      ) : (
        <span className="ClientTableIcon">
          <LoadingOutlined />
        </span>
      )}
    </>
  )
}
