import { useMutation, useQueryClient } from 'react-query'
import $api from '../../../http/$api'
import { endPoints } from '../utils/constants/endPoints'
import { queryKeys } from '../utils/constants/queryKeys'
import { message } from 'antd'

export function useBrokerCreate() {
  const qc = useQueryClient()
  return useMutation(
    async (data) => {
      if (data?.id) {
        const res = await $api.patch(endPoints.BROKER_UPDATE(data?.id), {
          name: data?.name,
          phone: data?.phone,
        })
        return res.data
      } else {
        const res = await $api.post(endPoints.BROKER, data)
        return res.data
      }
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryKeys.BROKER_ALL])
      },
      onError: (err) => {
        message.error(err.response?.data?.message)
      },
    }
  )
}
