import {
  ADD_SPONSOR,
  CLEAR_SPONSOR,
  DELETE_SPONSOR,
  UPDATE_SPONSOR,
} from './types'

const initialState = {
  sponsorList: [],
}

const sponsorReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case ADD_SPONSOR:
      return {
        ...state,
        sponsorList: [...state.sponsorList, payload],
      }
    case UPDATE_SPONSOR:
      let updatedSponsorList = state.sponsorList

      updatedSponsorList?.forEach((_, index) => {
        if (index === payload.index) {
          updatedSponsorList[index] = payload.sponsor
        }
      })

      return {
        ...state,
        sponsorList: updatedSponsorList,
      }
    case DELETE_SPONSOR:
      return {
        ...state,
        sponsorList: state.sponsorList.filter((_, index) => index !== payload),
      }
    case CLEAR_SPONSOR:
      return {
        ...state,
        sponsorList: [],
      }
    default:
      return state
  }
}

export default sponsorReducer
