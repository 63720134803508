import { Popconfirm, Table } from 'antd'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setProducts } from '../../redux/actions'
import UpdateIcon from '../../icons/UpdateIcon'
import DeleteIcon from '../../icons/DeleteIcon'
import { useDeleteContractPart } from '../../queries/mutations'

const PartsOfContract = ({
  data,
  setModalVisible,
  setPartId,
  contractId,
  currency,
}) => {
  const [page, setPage] = useState(1)
  const { Column } = Table
  const dispatch = useDispatch()
  const deleteContractPart = useDeleteContractPart(contractId)

  // view products of part
  const viewPartProducts = (index) => {
    setPartId(data[index].id)
    setModalVisible(true)

    let products = data[index]?.products?.map((product) => ({
      ...product,
      isSave: true,
    }))
    dispatch(setProducts(products))
  }

  // delete part
  const deletePart = (partId) => {
    deleteContractPart.mutate(partId)
  }

  return (
    <Table
      dataSource={data}
      pagination={{
        hideOnSinglePage: true,
        current: page,
        onChange: (page) => setPage(page),
      }}
      rowKey={(record) => record.id}
    >
      <Column
        title="#"
        render={(_, __, index) => index + (page - 1) * 10 + 1}
      />
      <Column title="Sana" dataIndex="createdDate" />
      <Column
        title="Summa"
        render={(record) => `${record?.amount?.toLocaleString()} ${currency}`}
        align="right"
      />
      <Column
        width={100}
        render={(record, __, index) => (
          <div className="df jc-sa">
            <div onClick={() => viewPartProducts(index + (page - 1) * 10)}>
              <UpdateIcon />
            </div>
            &nbsp;
            {data?.length > 1 && (
              <Popconfirm
                title="Partiyani o'chirmoqchimisiz?"
                onConfirm={() => deletePart(record.id)}
                okText="ha"
                cancelText="yo'q"
                placement="left"
              >
                <div>
                  <DeleteIcon />
                </div>
              </Popconfirm>
            )}
          </div>
        )}
      />
    </Table>
  )
}

export default PartsOfContract
