import {
  Col,
  Modal,
  Row,
  Form,
  Input,
  Button,
  Space,
  Typography,
  message,
} from 'antd'
import moment from 'moment'
import React, { useRef, useState } from 'react'
import ReactInputMask from 'react-input-mask'
import { useQueryString } from '../../hooks/useQueryString'
import cardImg from '../../images/Card.png'
import {
  useCreatePaymeCard,
  useCreateUzCard,
  useVerifyPaymeCard,
  useVerifyUzCard,
} from '../../queries/mutations'
import { cardTypes } from '../../utils/constants/cardTypes'
import { ResendVerificationCode } from './ResendVerificationCode'

const { useForm, Item } = Form

const INPUT_STEP = 'input'
const CONFIRM_STEP = 'confirm'
const NUMBER_NAME = 'number'
const DATE_NAME = 'expire'
const CODE_NAME = 'code'
const USER_PHONE = 'userPhone'

export const AddCardModal = ({ isOpen, setIsOpen, cardType }) => {
  const verificationToken = useRef('')
  const verificationSession = useRef('')
  const { query } = useQueryString()
  //
  const createPaymeCard = useCreatePaymeCard()
  const createUzCard = useCreateUzCard()
  const verifyPaymeCard = useVerifyPaymeCard(setIsOpen)
  const verifyUzCard = useVerifyUzCard(setIsOpen)
  //
  const [step, setStep] = useState(INPUT_STEP)
  const [inputs, setInputs] = useState({
    number: '',
    date: '',
  })
  const [form] = useForm()

  const onCancel = () => {
    setIsOpen(false)
    setStep(INPUT_STEP)
    form.resetFields()
  }

  const afterClose = () => {
    onCancel()
  }

  const onFinish = async (values) => {
    if (step === INPUT_STEP) {
      if (cardType === cardTypes.PAYME) {
        const data = await createPaymeCard.mutateAsync({
          number: values[NUMBER_NAME].replace(/\s/g, ''),
          expire: values[DATE_NAME].replace('/', ''),
        })
        verificationToken.current = data.data.token
        setStep(CONFIRM_STEP)
      } else {
        const data = await createUzCard.mutateAsync({
          cardNumber: values[NUMBER_NAME].replace(/\s/g, ''),
          expireDate: values[DATE_NAME].split('/').reverse().join(''),
          userPhone: values[USER_PHONE].replace('+', ''),
          userId: query.contractId,
        })
        verificationSession.current = data.data.session
        setStep(CONFIRM_STEP)
      }
    } else if (step === CONFIRM_STEP) {
      return
    }
  }

  const sendVerivicationCode = async () => {
    const code = form.getFieldValue(CODE_NAME)

    if (code) {
      if (cardType === cardTypes.PAYME) {
        verifyPaymeCard.mutateAsync({
          code,
          token: verificationToken.current,
        })
      } else {
        verifyUzCard.mutate({
          otp: code,
          session: verificationSession.current,
        })
      }
    } else {
      message.error('Tasdiqlash kodini kiriting')
    }
  }

  return (
    <Modal
      footer={null}
      visible={isOpen}
      width={850}
      onCancel={onCancel}
      afterClose={afterClose}
    >
      <Row gutter={16}>
        <Col span={12} className="imgBox">
          <img alt="card" width="100%" src={cardImg} />
          <Typography.Paragraph className="cardTitle cardItem">
            {`Karta ${cardType}`}
          </Typography.Paragraph>
          <Typography.Paragraph className="cardNumber cardItem">
            {inputs.number || '**** **** **** ****'}
          </Typography.Paragraph>
          <Typography.Paragraph className="cardDate cardItem">
            {inputs.date || '**/**'}
          </Typography.Paragraph>
        </Col>
        <Col span={12} className="creditCardCol">
          <Form form={form} onFinish={onFinish} layout="vertical">
            <Item
              rules={[
                { required: true, message: 'Karta raqamini kiriting' },
                { max: 19, message: "Karta raqamini to'gri kiriting" },
                {
                  validator: (_, value) => {
                    if (isNaN(value.replace(/\s/g, ''))) {
                      return Promise.reject(
                        new Error("Karta raqamini to'gri kiriting")
                      )
                    }
                    return Promise.resolve()
                  },
                },
              ]}
              label="Karta raqami"
              name={NUMBER_NAME}
              onChange={({ target }) => {
                if (target.value) {
                  ///validation
                  const spacelessValue = target.value.replace(/\s/g, '')
                  if (spacelessValue.length >= 16) {
                    const result = target.value.slice(0, 19)
                    form.setFieldsValue({
                      [NUMBER_NAME]: result,
                    })
                    setInputs({
                      ...inputs,
                      number: result,
                    })
                    return
                  }
                  const result = spacelessValue.match(/.{1,4}/g).join(' ')
                  form.setFieldsValue({
                    [NUMBER_NAME]: result,
                  })
                  setInputs({
                    ...inputs,
                    number: result,
                  })
                }
              }}
            >
              {step === INPUT_STEP ? (
                <Input placeholder="1234 4312 1234 4321" />
              ) : (
                <p className="fieldText">{form.getFieldValue(NUMBER_NAME)}</p>
              )}
            </Item>
            <Item
              onChange={({ target }) => {
                if (target.value.length >= 3) {
                  const value = target.value
                    .replace(/\s/g, '')
                    .replace(/\//g, '')
                  const result = value.slice(0, 2) + '/' + value.slice(2, 4)
                  form.setFieldsValue({
                    [DATE_NAME]: result,
                  })
                  setInputs({
                    ...inputs,
                    date: result,
                  })
                }
              }}
              rules={[
                { required: true, message: 'Amal qilish muddatini kiriting' },
                { max: 7, message: "Amal qilish muddatini to'gri kiriting" },
                {
                  validator: (_, value) => {
                    if (isNaN(value.replace(/\s/g, '').replace('/', ''))) {
                      return Promise.reject(
                        new Error("Amal qilish muddatini to'gri kiriting")
                      )
                    }
                    return Promise.resolve()
                  },
                },
              ]}
              label="Amal qilish muddati"
              name={DATE_NAME}
            >
              {step === INPUT_STEP ? (
                <Input
                  placeholder={
                    '10/' +
                    moment().add(1, 'year').get('year').toString().slice(2)
                  }
                />
              ) : (
                <p className="fieldText">{form.getFieldValue(DATE_NAME)}</p>
              )}
            </Item>
            {cardType === cardTypes.UZCARD && step === INPUT_STEP && (
              <Item
                rules={[{ required: true, len: 13, message: '' }]}
                label="Telefon raqam"
                name={USER_PHONE}
              >
                {step === INPUT_STEP && (
                  <ReactInputMask mask="+\9\98999999999" maskChar="">
                    {(inputProps) => <Input {...inputProps} />}
                  </ReactInputMask>
                )}
              </Item>
            )}
            {step === CONFIRM_STEP && (
              <Item name={CODE_NAME} label="Tasdiqlash kodi">
                <Input autoComplete={'none'} />
              </Item>
            )}
          </Form>
          <Row style={{ marginTop: 'auto' }} justify="end">
            <Space>
              {step === CONFIRM_STEP && (
                <>
                  <ResendVerificationCode
                    cardType={cardType}
                    session={verificationSession.current}
                    token={verificationToken.current}
                  />
                  <Button
                    type="primary"
                    danger
                    onClick={() => {
                      setStep(INPUT_STEP)
                      form.setFieldsValue({
                        [CODE_NAME]: '',
                      })
                    }}
                  >
                    Orqaga
                  </Button>
                </>
              )}
              {step === INPUT_STEP ? (
                <Button
                  loading={createPaymeCard.isLoading || createUzCard.isLoading}
                  type="primary"
                  onClick={() => {
                    form.submit()
                  }}
                >
                  SMS yuborish
                </Button>
              ) : (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={verifyPaymeCard.isLoading || verifyUzCard.isLoading}
                  onClick={sendVerivicationCode}
                >
                  Tasdiqlash
                </Button>
              )}
            </Space>
          </Row>
        </Col>
      </Row>
    </Modal>
  )
}
