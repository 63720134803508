import React, { useEffect } from 'react'
import { Col, DatePicker, Input, message, Row, Typography } from 'antd'
import PaymentTable from './PaymentTable'
import moment from 'moment'
import GlobalAssistant from '../../../services/GlobalAssistant'
import {
  setDelay,
  setFirstDate,
  setPaymentData,
  setRange,
  setResidue,
} from '../../../redux/actions'
import { connect } from 'react-redux'

const Payment = (props) => {
  const {
    totalPrice,
    firstPayment,
    delay,
    firstDate,
    range,
    setDelay,
    setFirstDate,
    setRange,
    setPaymentData,
    setResidue,
    residue,
    getProductInfo,
  } = props

  useEffect(() => {
    setPaymentData({
      totalPrice: getProductInfo().sum,
      firstPayment,
    })
    setResidue(getProductInfo().sum - firstPayment)
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <Typography
        style={{
          fontFamily: 'IBM Plex Sans',
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '18px',
          lineHeight: '100%',
          color: '#989FB9',
          margin: '20px 0 0 40px',
          textAlign: 'center',
        }}
      >
        Shartnoma to'lovlari:
      </Typography>
      <Row
        gutter={[48, 40]}
        style={{
          width: '79vw',
          paddingBottom: '0',
          margin: 'auto',
        }}
      >
        <Col sm={24} md={24} lg={12} style={{ width: '50%' }}>
          <div
            style={{
              width: '384px',
              height: '180px',
              background: '#FFFFFF',
              boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.05)',
              borderRadius: '6px',
              margin: '20px 0 30px 30px',
              padding: '0 5px',
              paddingTop: '20px',
            }}
          >
            <Typography
              style={{
                fontFamily: 'IBM Plex Sans',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '17px',
                lineHeight: '100%',
                color: '#989FB9',
              }}
            >
              <span
                style={{
                  display: 'inline-block',
                  width: '215px',
                  textAlign: 'right',
                  marginRight: '0',
                }}
              >
                Umumiy summa:
              </span>
              <span style={{ paddingLeft: '4px', cursor: 'default' }}>
                <>
                  <Input
                    disabled
                    style={{
                      display: 'inline',
                      width: '140px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                      marginRight: '3px',
                    }}
                    value={totalPrice.toLocaleString('ru')}
                  />
                </>
              </span>
            </Typography>
            <Typography
              style={{
                fontFamily: 'IBM Plex Sans',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '17px',
                lineHeight: '100%',
                color: '#989FB9',
                margin: '15px 0 0 0',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span
                style={{
                  display: 'inline-block',
                  width: '215px',
                  textAlign: 'right',
                }}
              >
                Boshlang'ich to'lov:
              </span>
              <span style={{ paddingLeft: '4px', cursor: 'default' }}>
                <>
                  <Input
                    style={{
                      display: 'inline',
                      width: '140px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                      marginRight: '3px',
                    }}
                    placeholder={firstPayment}
                    value={
                      firstPayment === 0 || !firstPayment
                        ? null
                        : firstPayment.toLocaleString('ru')
                    }
                    onChange={(e) => {
                      const val = GlobalAssistant.deleteNunNumbers(
                        e.target.value
                      )
                      if (val) {
                        if (totalPrice - parseInt(val) >= 0) {
                          setPaymentData({
                            totalPrice,
                            firstPayment: parseInt(val),
                          })
                          const newResidue = totalPrice - parseInt(val)
                          if (newResidue === 0) {
                            setDelay(0)
                          } else {
                            setDelay(6)
                          }
                          setResidue(newResidue)
                        } else {
                          message.error("Boshlang'ich summa katta!")
                        }
                      } else {
                        setPaymentData({
                          totalPrice,
                          firstPayment: 0,
                        })
                        setResidue(totalPrice)
                      }
                    }}
                  />
                </>
              </span>
            </Typography>
            <Typography
              style={{
                fontFamily: 'IBM Plex Sans',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '17px',
                lineHeight: '100%',
                color: '#989FB9',
                margin: '15px 0 0 0',
              }}
            >
              <span
                style={{
                  display: 'inline-block',
                  width: '215px',
                  textAlign: 'right',
                }}
              >
                Qoldiq summa:
              </span>
              <span style={{ paddingLeft: '4px', cursor: 'default' }}>
                <>
                  <Input
                    disabled
                    style={{
                      display: 'inline',
                      width: '140px',
                      paddingLeft: '10px',
                      paddingRight: '10px',
                      marginRight: '3px',
                    }}
                    value={residue.toLocaleString('ru')}
                  />
                </>
              </span>
            </Typography>
          </div>
        </Col>
        <Col sm={24} md={24} lg={12} style={{ width: '50%' }}>
          <div
            style={{
              width: '384px',
              height: '180px',
              background: '#FFFFFF',
              boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.05)',
              borderRadius: '6px',
              margin: '20px 0 30px 30px',
              padding: '0 5px',
              paddingTop: '20px',
            }}
          >
            <Typography
              style={{
                fontFamily: 'IBM Plex Sans',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '17px',
                lineHeight: '100%',
                color: '#989FB9',
                margin: '0',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span
                style={{
                  display: 'inline-block',
                  width: '215px',
                  textAlign: 'right',
                }}
              >
                Birinchi to'lov sanasi:
              </span>
              <span style={{ paddingLeft: '4px', cursor: 'default' }}>
                <>
                  <DatePicker
                    format="DD.MM.YYYY"
                    allowClear={false}
                    value={moment(firstDate, 'DD-MM-YYYY')}
                    placeholder={'Sanani tanlang'}
                    style={{
                      display: 'inline-block',
                      padding: '7px 10px',
                      width: '140px',
                    }}
                    onChange={(date, dateString) => {
                      setFirstDate(dateString)
                    }}
                  />
                </>
              </span>
            </Typography>
            <Typography
              style={{
                fontFamily: 'IBM Plex Sans',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '100%',
                color: '#989FB9',
                margin: '15px 0 0 0',
              }}
            >
              <label
                htmlFor="delay"
                style={{
                  fontSize: '17px',
                  display: 'inline-block',
                  width: '215px',
                  textAlign: 'right',
                }}
              >
                Kreditni qaytarish muddati:
              </label>
              <span style={{ paddingLeft: '20px' }}>
                <span
                  style={{
                    cursor: 'pointer',
                    fontSize: '20px',
                    padding: '5px',
                    userSelect: 'none',
                  }}
                  onClick={() => {
                    let getDelay = delay * 1 - 6 <= 1 ? 1 : delay * 1 - 6
                    setDelay(getDelay)
                  }}
                >
                  {'<'}
                </span>
                <Input
                  id="delay"
                  style={{
                    display: 'inline-block',
                    color: 'black',
                    width: '58px',
                    textAlign: 'center',
                    padding: '0 3px',
                    fontSize: '17px',
                  }}
                  placeholder={delay === 1 && '1'}
                  value={delay === 1 ? undefined : delay}
                  onChange={(e) => {
                    const getDelay = parseInt(e.target.value)
                      ? parseInt(e.target.value)
                      : 1
                    if (getDelay) {
                      if (getDelay < 1) {
                        setDelay(1)
                        message.error('Muddat xato kiritilgan!')
                      }
                      if (getDelay > 240) {
                        setDelay(240)
                        message.error('Muddat xato kiritilgan!')
                      } else setDelay(parseInt(getDelay))
                    } else {
                      setDelay(1)
                      message.error('Muddat xato kiritilgan!')
                    }
                  }}
                />
                <span
                  style={{
                    cursor: 'pointer',
                    fontSize: '20px',
                    padding: '5px',
                    userSelect: 'none',
                  }}
                  onClick={() => {
                    let getDelay = delay * 1 + 6 >= 240 ? 240 : delay * 1 + 6
                    setDelay(getDelay)
                  }}
                >
                  {'>'}
                </span>
                <span style={{ color: '#434A59' }}>oy</span>
              </span>
            </Typography>
            <Typography
              style={{
                fontFamily: 'IBM Plex Sans',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '17px',
                lineHeight: '100%',
                color: '#989FB9',
                margin: '15px 0 0 0',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span
                style={{
                  display: 'inline-block',
                  width: '215px',
                  textAlign: 'right',
                  marginRight: '0',
                }}
              >
                Vaqt oralig'i:
              </span>
              <span style={{ paddingLeft: '4px', cursor: 'default' }}>
                <>
                  <Input
                    style={{
                      display: 'inline',
                      width: '140px',
                      marginRight: '3px',
                    }}
                    type={'number'}
                    placeholder={range === 1 && '1'}
                    value={range === 1 ? undefined : range}
                    onChange={(e) => {
                      const getRange = parseInt(e.target.value)
                        ? parseInt(e.target.value)
                        : 1
                      if (getRange > 0) {
                        setRange(getRange)
                      } else {
                        setRange(1)
                        message.error("Vaqt oralig'i kiritilgan!")
                      }
                    }}
                  />
                </>
              </span>
            </Typography>
          </div>
        </Col>
      </Row>
      {residue !== 0 && <PaymentTable />}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    firstPayment: state.paymentCont.firstPayment,
    totalPrice: state.paymentCont.totalPrice,
    delay: state.paymentCont.delay,
    range: state.paymentCont.range,
    firstDate: state.paymentCont.firstDate,
    residue: state.paymentCont.residue,
  }
}

const mapDispatchToProps = {
  setDelay,
  setFirstDate,
  setRange,
  setPaymentData,
  setResidue,
}

export default connect(mapStateToProps, mapDispatchToProps)(Payment)
