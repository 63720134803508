import React, { useEffect, useState } from 'react'
import { Button, Input, message, Select, Typography } from 'antd'
import { useMessageText } from '../../../queries/queries'
import { useChangeMessageTextMutation } from '../../../queries/mutations'

const { TextArea } = Input
const { Option } = Select

const paymentParams = [
  {
    value: '@contract_number',
    label: 'Shatnoma raqami',
  },
  {
    value: '@amount',
    label: 'Summasi',
  },
  {
    value: '@residue',
    label: 'Qolgan summa',
  },
]

const monthlyPaymentParams = [
  {
    value: '@residue',
    label: 'Summasi',
  },
  {
    value: '@interest_sum',
    label: 'Peniya',
  },
]

const contractParams = [
  {
    value: '@contract_number',
    label: 'Shartnoma raqami',
  },
  {
    value: '@name',
    label: 'Mijoz ismi',
  },
]

const Message = () => {
  const [params, setParams] = useState([])
  const [valueMessage, setValueMessage] = useState('')
  const [messageObj, setMessageObj] = useState({
    id: null,
    text: '',
    type: '',
  })

  const { data, isLoading, isFetching } = useMessageText(valueMessage)
  const changeText = useChangeMessageTextMutation(valueMessage)

  useEffect(() => {
    setMessageObj(data)
    switch (valueMessage) {
      case 'PAYMENT':
        setParams(paymentParams)
        break
      case 'BEFORE_MONTHLY_PAYMENT':
        setParams(monthlyPaymentParams)
        break
      case 'AFTER_MONTHLY_PAYMENT':
        setParams(monthlyPaymentParams)
        break
      case 'CONTRACT':
        setParams(contractParams)
        break
      case 'END_CONTRACT':
        setParams(contractParams)
        break
      default:
        break
    }
    // eslint-disable-next-line
  }, [data])

  const saveFunc = () => {
    if (messageObj.text) {
      changeText.mutate(messageObj)
    } else {
      message.error("xabar matni bo'sh")
    }
  }

  return (
    <div
      style={{
        background: '#FFFFFF',
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.05)',
        borderRadius: 6,
        padding: 24,
      }}
    >
      <Typography className="ClientAddHeader">Xabarlarni sozlash</Typography>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <label className="SettingsMessageMainLabel" htmlFor="mainLabel">
          Xabar turi
        </label>
        <Select
          loading={isLoading || isFetching}
          id="mainLabel"
          placeholder="Xabar turi..."
          style={{
            marginLeft: '80px',
            width: '350px',
          }}
          onChange={(e) => setValueMessage(e)}
        >
          <Option value="PAYMENT">To'lov qilinganda</Option>
          <Option value="BEFORE_MONTHLY_PAYMENT">
            Oylik to'lovdan 1 kun oldin
          </Option>
          <Option value="AFTER_MONTHLY_PAYMENT">
            Oylik to'lovdan 1 kun keyin
          </Option>
          <Option value="CONTRACT">Shartnoma qilinganda</Option>
          <Option value="END_CONTRACT">Shartnoma bekor qilinganda</Option>
        </Select>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'start',
          marginTop: '25px',
        }}
      >
        <div>
          <div
            className="SettingsMessageMainLabel"
            style={{
              margin: '5px',
              marginLeft: '0',
              marginTop: '0',
            }}
          >
            Xabar matni
          </div>
          <Select
            placeholder="O'zgarmaslar..."
            style={{
              width: '150px',
            }}
            onChange={(e) => {
              setMessageObj({
                id: messageObj?.id,
                text: messageObj?.text + ' ' + e,
                type: messageObj?.type,
              })
            }}
          >
            {params.map((param) => (
              <Option key={param.value} value={param.value}>
                {param.label}
              </Option>
            ))}
          </Select>
        </div>
        <div
          style={{
            marginLeft: '10px',
          }}
        >
          <TextArea
            rows={8}
            placeholder="Xabar matni..."
            value={messageObj?.text}
            onChange={(e) => {
              setMessageObj({
                id: messageObj?.id,
                text: e.target.value,
                type: messageObj?.type,
              })
            }}
            style={{
              resize: 'none',
              width: '350px',
            }}
          />
        </div>
        <Button
          type="primary"
          loading={changeText.isLoading}
          disabled={!messageObj?.id}
          style={{
            margin: '0 10px',
          }}
          onClick={saveFunc}
        >
          Saqlash
        </Button>
      </div>
    </div>
  )
}

export default Message
