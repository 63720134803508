import React, { useEffect } from 'react'
import styles from './createBroker.module.scss'
import { Button, Col, Form, Input, Modal, Row } from 'antd'
import ReactInputMask from 'react-input-mask'
import { useBrokerCreate } from '../../services/mutation'

const CreateBroker = ({ createBroker, setCreateBroker }) => {
  const createBrokerMutate = useBrokerCreate()
  const [form] = Form.useForm()

  const handleClose = () => {
    setCreateBroker((prev) => ({ ...prev, id: null, visible: false }))
    form.resetFields()
  }

  const onFinish = (values) => {
    createBrokerMutate
      .mutateAsync({
        name: values.name,
        phone: values.phone.replace(/\s/g, ''),
        id: createBroker?.id,
      })
      .then(() => {
        form.resetFields()
        handleClose()
      })
  }

  useEffect(() => {
    if (createBroker?.id) {
      form.setFieldsValue({
        name: createBroker?.name,
        phone: createBroker?.phone,
      })
    }

    return () => {
      form.resetFields()
    }
  }, [createBroker])

  return (
    <Modal
      title={createBroker?.id ? `O'zgartirish` : `Vositachi yaratish`}
      visible={createBroker?.visible}
      onCancel={handleClose}
      wrapClassName={styles.modal}
      footer={
        <>
          <Button onClick={handleClose}>Bekor qilish</Button>
          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={createBrokerMutate.isLoading}
          >
            {createBroker?.id ? "O'zgartirish" : 'Yaratish'}
          </Button>
        </>
      }
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item label="Ismi" name={'name'}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Telefon" name={'phone'}>
              <ReactInputMask
                maskChar=""
                mask="+\9\98 99 999 99 99"
                className="ant-input"
              ></ReactInputMask>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default CreateBroker
