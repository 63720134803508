import { useHistory } from 'react-router-dom'
import { useMemo } from 'react'
import queryString from 'query-string'

export function useQueryString() {
  const { location, push } = useHistory()
  const query = queryString.parse(location.search)
  const result = useMemo(
    () => ({
      push: (key, value) => {
        query[key] = value
        push(location.pathname + '?' + queryString.stringify(query) + location.hash)
      },
      append: (key, value) => {
        query[key] = value
        return queryString.stringify(query)
      },
      query,
    }),
    [location.pathname, push, query, location.hash]
  )
  return result
}
