import React from 'react'
import { Col, Row } from 'antd'
import Menu from './Menu'
import Message from './contents/Message'
import Employee from './contents/Employee'
import NotAccess from './contents/NotAccess'
import AllMessage from './contents/AllMessage'
import { withRouter } from 'react-router'
import { OtherCompanySettings } from './contents/OtherCompanySettings'
import CurrencyConfig from './contents/CurrencyConfig'
import MessageSystem from './contents/message-system/MessageSystem'

const menuButtons = [
  {
    title: 'Umumiy xabarlar',
    content: 'ALLMESSAGE',
  },
  {
    title: 'Xabar sozlamalari',
    content: 'MESSAGE',
  },
  // {
  //   title: 'Xabarnoma tizimi',
  //   content: 'MESSAGE_SYSTEM',
  // },
  {
    title: 'Xodimlar',
    content: 'EMPLOYEE',
  },
  {
    title: 'Kompaniya',
    content: 'COMPANY',
  },
  {
    title: 'Valyuta sozlamalari',
    content: 'CURRENCY_CONFIG',
  },
]

class Core extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: this.props.location.hash.replace('#', '') || 'MESSAGE',
    }
  }

  getRoleCode() {
    if (localStorage.getItem('user')) {
      return JSON.parse(localStorage.getItem('user')).roles[0]
    } else {
      return null
    }
  }

  getContent() {
    if (this.getRoleCode() === 'SUPER_ADMIN') {
      switch (this.props.location.hash.replace('#', '')) {
        case 'ALLMESSAGE':
          return <AllMessage />
        case 'MESSAGE':
          return <Message />
        // case 'MESSAGE_SYSTEM':
        //   return <MessageSystem />
        case 'COMPANY':
          return <OtherCompanySettings />
        case 'EMPLOYEE':
          return <Employee />
        case 'CURRENCY_CONFIG':
          return <CurrencyConfig />
        default:
          return <Message />
      }
    } else {
      switch (this.state.content) {
        case 'ALLMESSAGE':
          return <AllMessage />
        case 'MESSAGE':
          return <Message />
        default:
          return <NotAccess />
      }
    }
  }

  contentTo = (v) => {
    this.setState({
      content: v,
    })
    this.props.history.push(this.props.location.pathname + '#' + v)
  }

  render() {
    return (
      <>
        <Row>
          <Col span={12} offset={4}>
            <h2
              style={{ textAlign: 'left', marginTop: 32, marginLeft: 12 + 8 }}
            >
              {'Tizim sozlamalari'}
            </h2>
          </Col>
        </Row>
        <Row gutter={[32, 32]} style={{ margin: 12 }}>
          <Col span={4}>
            <Menu
              content={this.props.location.hash.replace('#', '') || 'MESSAGE'}
              contentTo={this.contentTo}
              menuButtons={menuButtons}
            />
          </Col>
          <Col span={18}>{this.getContent()}</Col>
          <Col span={2} />
        </Row>
      </>
    )
  }
}

export default withRouter(Core)
