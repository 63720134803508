import { useMutation, useQueryClient } from 'react-query'
import $api from '../../../http/$api'
import { endPoints } from '../utils/constants/endPoints'
import { queryKeys } from '../utils/constants/queryKeys'
import { message } from 'antd'

export function useBlackListCreate() {
  const qc = useQueryClient()
  return useMutation(
    async (id) => {
      const res = await $api.post(endPoints.BLACKLIST + `?id=${id}`)
      return res.data
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryKeys.BLACKLIST])
      },
      onError: (err) => {
        message.error(err.response?.data?.message)
      },
    }
  )
}

export function useBlackListDelete() {
  const qc = useQueryClient()
  return useMutation(
    async (id) => {
      const res = await $api.delete(endPoints.BLACKLIST_ID(id))
      return res.data
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryKeys.BLACKLIST])
      },
      onError: (err) => {
        message.error(err.response?.data?.message)
      },
    }
  )
}
