import React, { useState } from 'react'
import {
  Input,
  Layout,
  Pagination,
  Space,
  Select,
  Row,
  Col,
  DatePicker,
  Button,
} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { VscListFilter } from 'react-icons/vsc'
import TablePayments from './TablePayments'
// import ShowSearchResults from "./ShowSearchResults";
import { usePayments } from '../../queries/queries'
import ExcelIcon from '../../icons/ExcelIcon'
import { useDownloadAllPaymentsExcel } from '../../queries/mutations'
// import {useDownloadExcelPaymentsMutation} from "../../queries/mutations";

const { Content } = Layout
const { Option } = Select
const { RangePicker } = DatePicker

const initialFilter = {
  start: null,
  finish: null,
  types: null,
  name: null,
}

function Payment() {
  const [filter, setFilter] = useState(false)
  const [filterObj, setFilterObj] = useState(initialFilter)
  const [searchString, setSearchString] = useState({
    filter: '?page=1&size=10',
    sum: '?',
  })
  const downloadAllPaymentsExcel = useDownloadAllPaymentsExcel(filterObj)
  // const [showPaymentToday, setShowPaymentToday] = useState (true);

  const { data, isLoading, isFetching } = usePayments(searchString)
  // const excelPayments = useDownloadExcelPaymentsMutation ()

  // const getExcel = () => {
  //   excelPayments.mutate (`?page=0&size=${data?.table?.totalElements}` + requestUrlFunc ())
  // }

  const requestUrlFunc = () => {
    let url = ''
    Object.keys(filterObj).forEach((item) => {
      if (Array.isArray(filterObj[item])) {
        if (filterObj[item].length !== 0) {
          url = url + `&${item}=`
          filterObj[item].forEach((status) => {
            url = url + status + ','
          })
        }
      } else if (filterObj[item]) {
        url = url + `&${item}=${filterObj[item]}`
      }
    })
    return url
  }

  function filterAndNextPageFunc(val, isNew) {
    if (isNew) {
      setSearchString({
        filter: '?page=1&size=10',
        sum: '?',
      })
      // setShowPaymentToday (true)
      setFilterObj(initialFilter)
    } else {
      const newSearchString = `?page=${val}&size=10${requestUrlFunc()}`
      setSearchString({
        filter: newSearchString,
        sum: `/?${requestUrlFunc().substring(1)}`,
      })
      // if (`?page=${numberPageLet}&size=10` === newSearchString) {
      //   setShowPaymentToday (true)
      // } else {
      //   setShowPaymentToday (false)
      // }
    }
  }

  // download
  const downloadExcel = () => {
    downloadAllPaymentsExcel.mutate()
  }

  return (
    <div>
      <Content
        style={{
          padding: '10px 20px 0 20px',
          overflow: 'initial',
          backgroundColor: '#FAFBFD',
        }}
      >
        <Space
          direction="horizontal"
          style={{ width: '100%', margin: '20px 20px', marginRight: '0' }}
        >
          <Input
            className="circle-input"
            style={{ width: 400, height: '38px' }}
            placeholder="Tezkor qidiruv..."
            onChange={(e) => {
              setFilterObj({
                ...filterObj,
                name: e.target.value,
              })
            }}
            onPressEnter={() => {
              filterAndNextPageFunc(1)
            }}
            prefix={<SearchOutlined className="site-form-item-icon" />}
            // suffix={
            //   <Tooltip title="Qo`llanma">
            //     <InfoCircleOutlined onClick={() => {
            //       setDrawerOpen (true)
            //     }} style={{color: "rgba(0,0,0,.45)"}}/>
            //   </Tooltip>
            // }
          />
          <button
            type="button"
            onClick={() => {
              filterAndNextPageFunc(1, true)
              setFilter(!filter)
            }}
            className="ClientFiltrButton"
            // style={filter ? {backgroundColor: '#7944E3'} : null}
          >
            <VscListFilter
              style={{
                position: 'absolute',
                fontSize: '1.4rem',
                left: '20px',
                fontFamily: 'IBM Plex Sans',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '18px',
                letterSpacing: '0px',
              }}
            />
            <span
              style={{
                margin: '0 0 0 25px',
                fontFamily: 'IBM Plex Sans',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '18px',
                letterSpacing: '0px',
              }}
            >
              Filtr
            </span>
          </button>
          <button
            type="button"
            onClick={downloadExcel}
            className="ClientFiltrButton excelBtn"
          >
            <ExcelIcon />
            <span
              style={{
                // margin: '0 0 0 25px',
                fontFamily: 'IBM Plex Sans',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '18px',
                letterSpacing: '0px',
              }}
            >
              Yuklab olish
            </span>
          </button>
          {/*{showPaymentToday ?*/}
          {/*  <Typography className="filterFieldTitle" style={{*/}
          {/*    cursor: 'default'*/}
          {/*  }}>*/}
          {/*    Bugungi tushumlar:*/}
          {/*    <NumberFormat*/}
          {/*      style={{margin: '0 10px'}}*/}
          {/*      value={data?.sumToday}*/}
          {/*      displayType={'text'}*/}
          {/*      thousandSeparator={" "}*/}
          {/*      prefix={""}/>*/}
          {/*    so'm*/}
          {/*  </Typography> :*/}
          {/*  <Typography className="filterFieldTitle" style={{*/}
          {/*    cursor: 'default'*/}
          {/*  }}>*/}
          {/*    Umumiy tushumlar:*/}
          {/*    <NumberFormat*/}
          {/*      style={{margin: '0 10px'}}*/}
          {/*      value={data?.sum?.SUM}*/}
          {/*      displayType={'text'}*/}
          {/*      thousandSeparator={" "}*/}
          {/*      prefix={""}/>*/}
          {/*    so'm*/}
          {/*  </Typography>}*/}
        </Space>
      </Content>
      {filter && (
        <div>
          <Row align="top">
            <Col span={6} style={{ margin: '15px 5px' }}>
              <h1 className="filterFieldTitle">Sana:</h1>
              <RangePicker
                id="time"
                allowClear={true}
                placeholder={['Boshlanish', 'Tugash']}
                style={{ width: '100%' }}
                format="DD.MM.YYYY"
                onChange={(date, dateString) => {
                  if (dateString[0]) {
                    setFilterObj({
                      ...filterObj,
                      start: dateString[0],
                      finish: dateString[1],
                    })
                  } else {
                    setFilterObj({
                      ...filterObj,
                      start: null,
                      finish: null,
                    })
                  }
                }}
              />
            </Col>
            <Col span={5} style={{ margin: '15px 0' }}>
              <h1 className="filterFieldTitle">To`lov turi:</h1>
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="To`lov turi"
                onChange={(e) => {
                  setFilterObj({
                    ...filterObj,
                    types: e,
                  })
                }}
              >
                <Option value={'CASH'}>Naqd</Option>
                <Option value={'TRANSFER'}>P2P</Option>
                <Option value={'BANK'}>Bank</Option>
                <Option value={'CARD'}>Karta</Option>
              </Select>
            </Col>
            <Col lg={5} style={{ margin: '15px 0' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                }}
              >
                <Button
                  type={'primary'}
                  style={{
                    marginTop: '27px',
                  }}
                  onClick={() => {
                    filterAndNextPageFunc(1)
                  }}
                >
                  Qidirish
                </Button>
                {/*<Button*/}
                {/*  loading={excelPayments?.isLoading}*/}
                {/*  type={"primary"}*/}
                {/*  style={{*/}
                {/*    marginTop: '27px'*/}
                {/*  }}*/}
                {/*  onClick={() => {*/}
                {/*    getExcel ()*/}
                {/*  }}>*/}
                {/*  Chop qilish*/}
                {/*</Button>*/}
              </div>
            </Col>
          </Row>
        </div>
      )}
      <TablePayments
        isFetching={isFetching}
        data={data?.table?.payments || []}
        spinner={isLoading}
      />
      <Pagination
        current={data?.table?.currentPage}
        pageSize={'10'}
        showSizeChanger={false}
        total={data?.table?.totalElements}
        onChange={(e) => {
          filterAndNextPageFunc(parseInt(e))
        }}
      />
      {/*<ShowSearchResults filterInfo={data?.sum}/>*/}
    </div>
  )
}

export default Payment
