export const deleteNotNumbersAndParseFloatNumber = (str,decimals=2) => {
  let _round = Math.round;

  let multiplier = Math.pow(10, decimals);
  return _round(str * multiplier) / multiplier;

  // let newValue = String(str)
  //   .replace(/[^\d.,]/g, '')
  //   .replaceAll(/,/g, '.')

  // const arr = newValue.split('.')

  // if (arr.length === 1) {
  //   newValue = newValue ? (+newValue).toLocaleString('ru') : ''
  // } else if (arr.length === 2) {
  //   newValue = newValue
  //     ? (+arr[0]).toLocaleString('ru') + ',' + arr[1]?.slice(0, 2)
  //     : ''
  // } else {
  //   newValue = newValue ? (+arr[0]).toLocaleString('ru') + ',' + arr[1] : ''
  // }

  // return Number(newValue.replaceAll(/\s/g, '').replaceAll(/,/g, '.'))
}
