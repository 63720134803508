import { SET_PROJECT_VERSION } from './types'

const initialState = {
  version: null,
}

const projectVersionReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_PROJECT_VERSION:
      return {
        ...state,
        version: payload,
      }
    default:
      return state
  }
}

export default projectVersionReducer
