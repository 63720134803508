import React, { useState } from 'react'
import { Table, Space, Input, Typography, Pagination } from 'antd'

import { useClients } from '../../queries/queries'
import { SearchOutlined } from '@ant-design/icons'
import NumberFormat from 'react-number-format'
import { Content } from 'antd/lib/layout/layout'
import { useHistory } from 'react-router-dom'

const Clients = () => {
  const [searchText, setSearchText] = useState('')
  const [page, setPage] = useState(1)
  const { data, isLoading } = useClients(page, searchText)
  const { Column } = Table
  const timerRef = React.useRef(null)
  const history = useHistory()

  return (
    <div>
      <Content
        style={{
          padding: '10px 20px 0 20px',
          overflow: 'initial',
          backgroundColor: '#FAFBFD',
        }}
      >
        <Space
          direction="horizontal"
          style={{ width: '100%', margin: '20px 20px', marginRight: '0' }}
        >
          <Input
            className="circle-input"
            style={{ width: 400, height: '38px' }}
            placeholder="Tezkor qidiruv..."
            prefix={<SearchOutlined className="site-form-item-icon" />}
            onChange={(e) => {
              clearTimeout(timerRef.current)
              timerRef.current = setTimeout(() => {
                setSearchText(e.target.value.length > 2 ? e.target.value : null)
              }, 1000)
            }}
          />
          <Typography
            className="filterFieldTitle"
            style={{
              cursor: 'default',
            }}
          >
            Mijozlar soni:
            <NumberFormat
              style={{ margin: '0 10px' }}
              value={data?.totalElements}
              displayType={'text'}
              thousandSeparator={' '}
              prefix={''}
            />
            ta
          </Typography>
        </Space>
      </Content>
      <Table
        dataSource={data?.data}
        loading={isLoading}
        pagination={false}
        size={'middle'}
        rowKey={(record) => record.id}
        onRow={(record) => ({
          onClick: () => {
            history.push(`/main/client/${record.id}`)
          },
        })}
      >
        <Column
          title="#"
          render={(_, __, index) => index + 1 + 10 * (data?.currentPage - 1)}
        />
        <Column title="Ismi" dataIndex="fullName" />
        <Column title="Manzili" dataIndex="address" />
      </Table>
      <Pagination
        current={data?.currentPage}
        pageSize={'10'}
        showSizeChanger={false}
        total={data?.totalPages}
        onChange={(e) => {
          setPage(e)
        }}
      />
    </div>
  )
}

export default Clients
