import { SearchOutlined } from '@ant-design/icons'
import { Button, Form, Input, message, Popconfirm } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import ReactInputMask from 'react-input-mask'
import { useDispatch } from 'react-redux'
import CalendarIcon from '../../icons/CalendarIcon'
import { useSearchClientBuPassportData } from '../../queries/queries'
import { addSponsor, deleteSponsor, updateSponsor } from '../../redux/actions'
import { contractTypes } from '../../utils/constants/contractTypes'
import { keyboardKeys } from '../../utils/constants/keyboardKeys'
import { validateDateString } from '../../utils/helper/validateDateString'

const { Item } = Form

const Sponsor = ({ data, index, type }) => {
  const [formInstance] = Form.useForm()
  const dispatch = useDispatch()
  const [searchClient, setSearchClient] = useState({
    passportSerialAndNumber: '',
    dateBirth: '',
  })
  const { isLoading, data: clientData } = useSearchClientBuPassportData(
    searchClient.passportSerialAndNumber,
    searchClient.dateBirth
  )

  // is specific
  const isSpecific = () => {
    if (type === contractTypes.SPECIFIC) return true
    return false
  }

  // initial form fields
  useEffect(() => {
    if (data) {
      formInstance.setFieldsValue({
        sponsorPassportData: data.passportData,
        sponsorDateBirth: data.dateBirth,
        sponsorPassportDate: data.passportDate,
        sponsorPassportPlace: data.passportPlace,
        sponsorFullName: data.fullName,
        sponsorPhone: data.phone,
        sponsorAddress: data.address,
      })
    }
  }, [data, formInstance])

  // on finish
  const onFinish = (fields) => {
    const sponsor = {
      passportData: fields.sponsorPassportData.toUpperCase().replace(' ', ''),
      dateBirth: fields.sponsorDateBirth,
      passportDate: fields.sponsorPassportDate,
      passportPlace: fields.sponsorPassportPlace,
      fullName: fields.sponsorFullName,
      phone: fields.sponsorPhone?.replace(/\s/g, ''),
      address: fields.sponsorAddress,
    }

    if (data) {
      dispatch(updateSponsor(sponsor, index))
    } else {
      dispatch(addSponsor(sponsor))
      formInstance.resetFields()
    }

    setSearchClient({
      passportSerialAndNumber: '',
      dateBirth: '',
    })
  }

  // handle submit
  const handleSubmit = () => {
    formInstance.submit()
  }

  // handle delete
  const handleDelete = () => {
    dispatch(deleteSponsor(index))
  }

  // handle key down
  const handleKeyDown = (e) => {
    if (e.key === keyboardKeys.ENTER) {
      handleSubmit()
    }
  }

  // search client
  const handleSearch = () => {
    if (
      formInstance.getFieldValue('sponsorPassportData') &&
      formInstance.getFieldValue('sponsorDateBirth')
    ) {
      setSearchClient({
        passportSerialAndNumber: formInstance
          .getFieldValue('sponsorPassportData')
          .toUpperCase()
          .replace(' ', ''),
        dateBirth: formInstance.getFieldValue('sponsorDateBirth'),
      })
    } else {
      message.error("Passport raqami yoki tug'ilgan kun kiritilmagan!")
    }
  }

  // client info
  useEffect(() => {
    if (clientData) {
      formInstance.setFieldsValue({
        sponsorFullName:
          clientData?.sur_name +
          ' ' +
          clientData?.name +
          ' ' +
          clientData?.patronymic_name,
        sponsorAddress: clientData?.address,
        sponsorPassportDate: moment(
          clientData?.given_date,
          'YYYY-MM-DD'
        ).format('DD.MM.YYYY'),
        sponsorPassportPlace: clientData?.given_place,
      })
    }
  }, [clientData, formInstance])

  return (
    <Form
      onFinish={onFinish}
      form={formInstance}
      className={isSpecific() ? 'specialContract' : ''}
    >
      <div className={`${!isSpecific() ? 'df' : ''}`}>
        <div className={'contractPlusInputsCont'}>
          <label
            htmlFor="sponsorPassportData"
            className={!isSpecific() ? 'YuridikShLabel' : ''}
          >
            <span className="title ta-l">
              {!isSpecific() ? 'Passport seriyasi,raqami:' : 'Pasporti:'}
            </span>
          </label>
          <Item
            style={
              !isSpecific()
                ? { paddingLeft: '17px', width: '100%' }
                : { width: '20rem' }
            }
            name={'sponsorPassportData'}
            rules={[
              {
                required: true,
                message: 'Pasporti kiritilmagan',
              },
            ]}
          >
            <ReactInputMask
              style={
                !isSpecific()
                  ? {
                      border: 'none',
                      borderBottom: '1px solid  #989FB9',
                      width: '200px',
                    }
                  : null
              }
              maskChar=""
              id="sponsorPassportData"
              mask="aa 9999999"
              onKeyDown={handleKeyDown}
              onChange={data && handleSubmit}
            >
              {(inputProps) => <Input {...inputProps} />}
            </ReactInputMask>
          </Item>
        </div>
        <div className={'contractPlusInputsCont'}>
          <label
            htmlFor="sponsorDateBirth"
            className={!isSpecific() ? 'YuridikShLabel' : ''}
            style={!isSpecific() ? { minWidth: '120px' } : null}
          >
            <span className="title ta-l">Tug'ilgan kun:</span>
          </label>
          <Item
            style={!isSpecific() ? { width: '160px' } : { width: '226px' }}
            name={'sponsorDateBirth'}
            rules={[
              {
                required: true,
                message: "Tug'ilgan sana kiritilmagan",
              },
              {
                validator: (_, value) => {
                  if (value) {
                    formInstance.setFieldsValue({
                      sponsorDateBirth: validateDateString(value),
                    })
                  }
                  return Promise.resolve()
                },
              },
            ]}
          >
            <ReactInputMask
              mask="99.99.9999"
              maskChar={''}
              style={
                !isSpecific()
                  ? {
                      border: 'none',
                      borderBottom: '1px solid  #989FB9',
                      width: '200px',
                    }
                  : null
              }
              onKeyDown={handleKeyDown}
              onChange={data && handleSubmit}
            >
              {(inputProps) => (
                <Input
                  suffix={<CalendarIcon />}
                  placeholder={'kk.oo.yyyy'}
                  {...inputProps}
                />
              )}
            </ReactInputMask>
          </Item>
          <Button
            onClick={handleSearch}
            loading={isLoading}
            style={{ marginLeft: '48px' }}
            type={'primary'}
          >
            <SearchOutlined />
          </Button>
        </div>
      </div>
      <h4>Pasport ma'lumotlari</h4>
      <div className={'contractPlusInputsCont'}>
        <label
          htmlFor="sponsorFullName"
          className={!isSpecific() ? 'YuridikShLabel' : ''}
        >
          <span className="title ta-l">F.I.SH:</span>
        </label>
        <Item
          style={
            !isSpecific()
              ? { paddingLeft: '17px', width: '100%' }
              : { width: '20rem' }
          }
          name={'sponsorFullName'}
          rules={[
            {
              required: true,
              message: 'Ismi kiritilmagan',
            },
          ]}
        >
          <Input
            style={
              !isSpecific()
                ? {
                    border: 'none',
                    borderBottom: '1px solid  #989FB9',
                    width: '500px',
                  }
                : null
            }
            onKeyDown={handleKeyDown}
            onChange={data && handleSubmit}
          />
        </Item>
      </div>
      <div className={'contractPlusInputsCont'}>
        <label
          htmlFor="sponsorPassportDate"
          className={!isSpecific() ? 'YuridikShLabel' : ''}
        >
          <span className="title ta-l">Berilgan sana:</span>
        </label>
        <Item
          style={
            !isSpecific()
              ? { paddingLeft: '17px', width: '100%' }
              : { width: '20rem' }
          }
          name={'sponsorPassportDate'}
          rules={[
            {
              required: true,
              message: 'Pasport berilgan sana kiritilmagan',
            },
            {
              validator: (_, value) => {
                if (value) {
                  formInstance.setFieldsValue({
                    sponsorPassportDate: validateDateString(value),
                  })
                }
                return Promise.resolve()
              },
            },
          ]}
        >
          {/* <DatePicker
            format="DD.MM.YYYY"
            style={
              !isSpecific()
                ? {
                    border: 'none',
                    borderBottom: '1px solid  #989FB9',
                    width: '200px',
                  }
                : null
            }
            onKeyDown={handleKeyDown}
            onChange={data && handleSubmit}
          /> */}
          <ReactInputMask
            mask="99.99.9999"
            maskChar={''}
            style={
              !isSpecific()
                ? {
                    border: 'none',
                    borderBottom: '1px solid  #989FB9',
                    width: '200px',
                  }
                : null
            }
            onKeyDown={handleKeyDown}
            onChange={data && handleSubmit}
          >
            {(inputProps) => (
              <Input
                suffix={<CalendarIcon />}
                placeholder={'kk.oo.yyyy'}
                {...inputProps}
              />
            )}
          </ReactInputMask>
        </Item>
      </div>
      <div className={'contractPlusInputsCont'}>
        <label
          htmlFor="sponsorPassportPlace"
          className={!isSpecific() ? 'YuridikShLabel' : ''}
        >
          <span className="title ta-l">Berilgan joy:</span>
        </label>
        <Item
          style={
            !isSpecific()
              ? { paddingLeft: '17px', width: '100%' }
              : { width: '20rem' }
          }
          name={'sponsorPassportPlace'}
          rules={[
            {
              required: true,
              message: 'Pasport berilgan joy kiritilmagan',
            },
          ]}
        >
          <Input
            style={
              !isSpecific()
                ? {
                    border: 'none',
                    borderBottom: '1px solid  #989FB9',
                    width: '500px',
                  }
                : null
            }
            onKeyDown={handleKeyDown}
            onChange={data && handleSubmit}
          />
        </Item>
      </div>
      <div className={'contractPlusInputsCont'}>
        <label
          htmlFor="sponsorAddress"
          className={!isSpecific() ? 'YuridikShLabel' : ''}
        >
          <span className="title ta-l">Manzili:</span>
        </label>
        <Item
          style={
            !isSpecific()
              ? { paddingLeft: '17px', width: '100%' }
              : { width: '20rem' }
          }
          name={'sponsorAddress'}
          rules={[
            {
              required: true,
              message: 'Manzili kiritilmagan',
            },
          ]}
        >
          <Input
            style={
              !isSpecific()
                ? {
                    border: 'none',
                    borderBottom: '1px solid  #989FB9',
                    width: '500px',
                  }
                : null
            }
            onKeyDown={handleKeyDown}
            onChange={data && handleSubmit}
          />
        </Item>
      </div>
      <div className={'contractPlusInputsCont'}>
        <label
          htmlFor="sponsorPhone"
          className={!isSpecific() ? 'YuridikShLabel' : ''}
        >
          <span className="title ta-l">Tel.raqami:</span>
        </label>
        <Item
          style={
            !isSpecific()
              ? { paddingLeft: '17px', marginRight: '16px' }
              : { marginRight: '16px' }
          }
          name={'sponsorPhone'}
          rules={[
            {
              required: true,
              message: 'Telefon raqami kiritilmagan',
            },
          ]}
        >
          <ReactInputMask
            style={
              !isSpecific()
                ? {
                    border: 'none',
                    borderBottom: '1px solid  #989FB9',
                    width: '200px',
                  }
                : null
            }
            maskChar=""
            id="sponsorsPhone"
            mask="+\9\98 99 999 99 99"
            onKeyDown={handleKeyDown}
            onChange={data && handleSubmit}
          >
            {(inputProps) => <Input {...inputProps} />}
          </ReactInputMask>
        </Item>
        {!data ? (
          <Button onClick={handleSubmit}>Saqlash</Button>
        ) : (
          <Popconfirm
            title="Kafilni o'chirmoqchimisiz?"
            placement="right"
            okText="Ha"
            cancelText="Yo'q"
            onConfirm={handleDelete}
          >
            <Button>O'chirish</Button>
          </Popconfirm>
        )}
      </div>
    </Form>
  )
}

export default Sponsor
