import { message } from 'antd'
import { useMutation, useQueryClient } from 'react-query'
import { apis } from '../http/apis'
import AuthService from '../services/AuthService'
import { getErrorArray } from '../services/getErrorArray'
import $api from './../http/$api'
import { queryNames } from './queryNames'
import { useQueryString } from '../hooks/useQueryString'
import { useHistory } from 'react-router'
import { modalTypes } from '../utils/constants/modalTypes'
import { contractTypes } from '../utils/constants/contractTypes'
import moment from 'moment'
import { stringify } from 'query-string'
import { contractStatus } from '../utils/constants/contractStatus'
import { downloadFile } from '../utils/helper/downloadFile'

export function useCompanyMutation(url) {
  const qc = useQueryClient()
  return useMutation(
    async (values) => {
      const res = await $api.post(url, values)
      return res?.data?.data
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([url])
        message.success('Yaratildi')
      },
      onError: (err) => {
        const errArray = getErrorArray(err?.response?.data?.message)
        errArray?.forEach((err) => {
          message.error(err)
        })
      },
    }
  )
}

export function useCompanyEditMutation(url) {
  const qc = useQueryClient()
  return useMutation(
    async (values) => {
      const res = await $api.patch(url, values)
      return res?.data?.data
    },
    {
      onSuccess: () => {
        message.success('Saqlandi')
        qc.invalidateQueries([url])
      },
      onError: (err) => {
        const errArray = getErrorArray(err?.response?.data?.message)
        errArray?.forEach((err) => {
          message.error(err)
        })
      },
    }
  )
}

export function useLoginMutation(history) {
  return useMutation(
    async (values) => {
      const res = await $api.post(apis.LOG_IN, values)
      return res?.data?.data
    },
    {
      onSuccess: (data) => {
        localStorage.setItem(
          'user',
          JSON.stringify({ ...data, accessToken: undefined })
        )
        localStorage.setItem('token', data.accessToken)
        const url = AuthService.getLandingPage(data?.roles[0])
        history.push(url)
      },
    }
  )
}

// export function useDownloadExcelPaymentsMutation(){
//   return useMutation (
//     async (searchString) => {
//       console.log (apis.PAYMENTS_EXCEL + searchString)
//       const res = await $api ({
//         method: 'get',
//         url: apis.PAYMENTS_EXCEL + searchString,
//         responseType: 'blob',
//       })
//       return res?.data
//     },
//     {
//       onSuccess: (data) => {
//         const downloadUrl = window.URL.createObjectURL (new Blob ([data]));
//         const link = document.createElement ('a');
//         link.href = downloadUrl;
//         link.setAttribute ('download', 'file.xlsx');
//         document.body.appendChild (link);
//         link.click ();
//         link.remove ();
//       }
//     }
//   )
// }

export function useDownloadExcelArrearageMutation() {
  return useMutation(
    async (searchString) => {
      const res = await $api({
        method: 'get',
        url: apis.ARREARAGE_LIST + apis.EXCEL + searchString,
        responseType: 'blob',
      })
      return {
        data: res?.data,
        name: 'qarzdorlik.xlsx',
      }
    },
    {
      onSuccess: (data) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data.data]))
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', data.name)
        document.body.appendChild(link)
        link.click()
        link.remove()
      },
      onError: (err) => {
        message.error(err.response?.data?.message)
        console.log(err.response?.data?.message)
      },
    }
  )
}

export function useDownloadExcelChangeDateArrearageMutation(date, brokerId) {
  return useMutation(
    async () => {
      const res = await $api({
        method: 'get',
        url:
          apis.ARREARAGE +
          apis.PERIOD +
          apis.EXCEL +
          '?date=' +
          date +
          '&brokerId=' +
          (brokerId || ''),
        responseType: 'blob',
      })
      return {
        data: res?.data,
        name: 'qarzdorlik (' + date + ').xlsx',
      }
    },
    {
      onSuccess: (data) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data.data]))
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', data.name)
        document.body.appendChild(link)
        link.click()
        link.remove()
      },
      onError: (err) => {
        message.error(err.response?.data?.message)
        console.log(err.response?.data?.message)
      },
    }
  )
}

export function useArrearageMessageMutation() {
  return useMutation(
    async () => {
      const res = await $api.post(apis.ARREARAGE_ALL + apis.MESSAGE)
      return res.data
    },
    {
      onSuccess: () => {
        message.success("Xabar jo'natildi")
      },
      onError: (err) => {
        message.error(err.response?.data?.message)
        console.log(err.response?.data?.message)
      },
    }
  )
}

export function useSendMessageOneClient() {
  return useMutation(
    async (data) => {
      const res = await $api.post(apis.CLIENT + `/${data?.id}` + apis.MESSAGE, {
        text: data?.text,
      })
      return res.data
    },
    {
      onSuccess: () => {
        message.success("Xabar jo'natildi")
      },
      onError: (err) => {
        message.error(err.response?.data?.message)
        console.log(err.response?.data?.message)
      },
    }
  )
}

export function useChangeMessageTextMutation(value) {
  const queryClient = useQueryClient()
  return useMutation(
    async (data) => {
      const res = await $api.post(apis.MESSAGE_TEMPLATE, data)
      return res.data
    },
    {
      onSuccess: () => {
        message.success('Xabar yangilandi')
        queryClient.invalidateQueries([queryNames.MESSAGE_TEXT, value])
      },
    }
  )
}

// export function useSendMessageAllClientsMutation() {
//   return useMutation(async (text) => {
//     const res = await $api.post(apis.SEND_MESSAGE_ALL_CLIENTS + text)
//     return res.data
//   })
// }

export function useCreateContractMutation(setContract, setModalOpen) {
  return useMutation(
    async (data) => {
      const res = await $api.post(apis.CONTRACT, data)
      return res.data?.data
    },
    {
      onError: (err) => {
        const errArray = getErrorArray(err?.response?.data?.message)
        errArray?.forEach((err) => {
          message.error(err)
        })
      },
      onSuccess: (data) => {
        setContract(data)
        setModalOpen(true)
        message.success('Shartnoma yaratildi!')
      },
    }
  )
}

export function useCancelContractMutation(id) {
  const queryClient = useQueryClient()
  return useMutation(
    async () => {
      const res = await $api.delete(`${apis.CONTRACT}/${id}`)
      return res?.data?.data
    },
    {
      onError: (err) => {
        message.error(err.response?.data?.message)
        console.log(err.response?.data?.message)
      },
      onSuccess: () => {
        queryClient.invalidateQueries([
          queryNames.CONTRACTS,
          queryNames.INFO,
          id,
        ])
        message.success('Shartnoma bekor qilindi!')
      },
    }
  )
}

export function useDownloadContract() {
  return useMutation(
    async (id) => {
      const res = await $api({
        method: 'get',
        url: `${apis.CONTRACTS}/${id}${apis.WORD}`,
        responseType: 'blob',
      })
      return {
        data: res?.data,
        name: id + '-shartnoma.docx',
      }
    },
    {
      onSuccess: (data) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data.data]))
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', data.name)
        document.body.appendChild(link)
        link.click()
        link.remove()
      },
      onError: (err) => {
        message.error(err.response?.data?.message)
        console.log(err.response?.data?.message)
      },
    }
  )
}

export function usePaymentMutation(closeFunc, clearRefFunc, downloadWord) {
  return useMutation(
    async (data) => {
      const res = await $api.post(
        `${apis.CONTRACT}/${data.id}${apis.PAYMENT}`,
        data.paymentBody
      )
      return res?.data?.data
    },
    {
      onSuccess: (data) => {
        downloadWord.mutate(data.id)
        message.success("To'lov amalga oshirildi")
        closeFunc()
      },
      onError: (err) => {
        message.error(err.response?.data?.message)
        console.log(err.response?.data?.message)
        if (clearRefFunc) clearRefFunc()
      },
    }
  )
}

export function useDownloadPaymentMutation() {
  return useMutation(
    async (id) => {
      const res = await $api({
        method: 'get',
        url: `${apis.PAYMENT}/${id}${apis.WORD}`,
        responseType: 'blob',
      })
      return {
        data: res?.data,
        name: id + '-tolov.docx',
      }
    },
    {
      onSuccess: (data) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data.data]))
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', data.name)
        document.body.appendChild(link)
        link.click()
        link.remove()
      },
      onError: (err) => {
        message.error(err.response?.data?.message)
        console.log(err.response?.data?.message)
      },
    }
  )
}

export function useDownloadPaymentsExcel() {
  return useMutation(
    async (id) => {
      const res = await $api({
        method: 'get',
        url: `${apis.CONTRACTS}/${id}${apis.PAYMENT}${apis.EXCEL}`,
        responseType: 'blob',
      })
      return {
        data: res?.data,
        name: id + '-shartnoma_tulovlari.xlsx',
      }
    },
    {
      onSuccess: (data) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data.data]))
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', data.name)
        document.body.appendChild(link)
        link.click()
        link.remove()
      },
      onError: (err) => {
        message.error(err.response?.data?.message)
        console.log(err.response?.data?.message)
      },
    }
  )
}

export function useDownloadAllPaymentsExcel(filterObj) {
  const filter = stringify(filterObj, {
    arrayFormat: 'comma',
    skipNull: true,
  })
  return useMutation(
    async () => {
      const res = await $api.get(apis.PAYMENTS_ALL_EXCEL + `?${filter}`, {
        responseType: 'blob',
      })
      return res.data
    },
    {
      onSuccess: (data) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute(
          'download',
          `To'lovlar ${moment().format('DD.MM.YYYY HH:mm')} .xlsx`
        )
        document.body.appendChild(link)
        link.click()
        link.remove()
      },
      onError: (err) => {
        message.error(err.response?.data?.message)
      },
    }
  )
}

export function useDownloadMonthlyPaymentMutation() {
  return useMutation(
    async (id) => {
      const res = await $api({
        method: 'get',
        url: `${apis.CONTRACTS}/${id}${apis.MONTHLY_PAYMENTS}${apis.WORD}`,
        responseType: 'blob',
      })
      return {
        data: res?.data,
        name: id + '-shartnom_jadvali.docx',
      }
    },
    {
      onSuccess: (data) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data.data]))
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', data.name)
        document.body.appendChild(link)
        link.click()
        link.remove()
      },
      onError: (err) => {
        message.error(err.response?.data?.message)
        console.log(err.response?.data?.message)
      },
    }
  )
}

export function useSmsMutation() {
  const queryClient = useQueryClient()
  return useMutation(
    async (data) => {
      const res = await $api.post(apis.BUILDING_COMPANY + apis.CONFIG, data)
      return res.data
    },
    {
      onSuccess: () => {
        message.success('Saqlandi')
        queryClient.invalidateQueries([queryNames.SMS_SETTINGS])
      },
    }
  )
}

export function useSendAllSmsMutation() {
  return useMutation(
    async (text) => {
      const res = await $api.post(apis.MESSAGES_ALL, { text })
      return res.data
    },
    {
      onSuccess: () => {
        message.success("Xabarlar jo'natildi")
      },
      onError: (e) => {
        console.log(e.response.data)
        message.error(e.response?.data?.message)
      },
    }
  )
}

// create payme card
export function useCreatePaymeCard() {
  return useMutation(
    async (data) => {
      const res = await $api.post(apis.CARD, data)
      return res.data
    },
    {
      onSuccess: (data) => {
        message.success(
          data.data.phone +
            " raqamiga tasdiqlash kodi jo'natildi, SMS xabarni kiriting",
          5
        )
      },
      onError: (err) => {
        message.error(err.response?.data?.message)
      },
    }
  )
}

// create uzcard
export function useCreateUzCard() {
  return useMutation(
    async (req) => {
      const res = await $api.post(apis.UZCARD, req)
      return res.data
    },
    {
      onSuccess: (data) => {
        message.success(
          data.data.otpSentPhone +
            " raqamiga tasdiqlash kodi jo'natildi, SMS xabarni kiriting",
          5
        )
      },
      onError: (err) => {
        message.error(err.response?.data?.message)
      },
    }
  )
}

// verify payme card
export function useVerifyPaymeCard(setIsOpen) {
  const { query } = useQueryString()
  const qc = useQueryClient()

  return useMutation(
    async (data) => {
      const res = await $api.post(
        apis.CARD + '/verify?code=' + data.code + '&token=' + data.token
      )
      if (res.data?.data) {
        const createResponse = await $api.post(
          apis.CONTRACT +
            '/' +
            query.contractId +
            apis.CARD +
            '?token=' +
            res.data?.data?.card?.token
        )
        return createResponse.data
      }
      return Promise.reject('error')
    },
    {
      onSuccess: () => {
        message.success('Kartani saqlandi')
        qc.invalidateQueries([queryNames.CONTRACTS])
        setIsOpen(false)
      },
      onError: (err) => {
        message.error(err.response?.data?.message)
      },
    }
  )
}

// verify uzcard
export function useVerifyUzCard(setIsOpen) {
  const { query } = useQueryString()
  const qc = useQueryClient()

  return useMutation(
    async (req) => {
      const res = await $api.post(
        `${apis.UZCARD}/confirm?contractId=${query.contractId}`,
        req
      )
      return res.data
    },
    {
      onSuccess: () => {
        message.success('Kartani saqlandi')
        qc.invalidateQueries([queryNames.CONTRACTS])
        setIsOpen(false)
      },
      onError: (err) => {
        message.error(err.response?.data?.message)
      },
    }
  )
}

// delete payme card
export function useDeletePaymeCard() {
  const qc = useQueryClient()
  return useMutation(
    async (id) => {
      const res = await $api.delete(apis.CONTRACT + '/' + id + apis.CARD)
      return res.data
    },
    {
      onSuccess: () => {
        message.success("Karta o'chirildi!")
        qc.invalidateQueries([queryNames.CONTRACTS])
      },
      onError: (err) => {
        message.error(err.response?.data?.message)
      },
    }
  )
}

// delete uzcard
export function useDeleteUzCard() {
  const qc = useQueryClient()

  return useMutation(
    async (contractId) => {
      const res = await $api.delete(`${apis.UZCARD}?contractId=${contractId}`)
      return res.data
    },
    {
      onSuccess: () => {
        message.success("Karta o'chirildi!")
        qc.invalidateQueries([queryNames.CONTRACTS])
      },
      onError: (err) => {
        message.error(err.response?.data?.message)
      },
    }
  )
}

// resend verification payme card
export function useResendVerificationPaymeCard() {
  return useMutation(
    async (token) => {
      const res = await $api.get(apis.CARD + '/verify?token=' + token)
      return res.data
    },
    {
      onSuccess: () => {
        message.success("Tasdiqlash kodi takroran jo'natildi")
      },
      onError: (err) => {
        message.error(err.response?.data?.message)
      },
    }
  )
}

// resend verification uzcard
export function useResendVerificationUzCard() {
  return useMutation(
    async (session) => {
      const res = await $api.get(`${apis.UZCARD}/resend?session=${session}`)
      return res.data
    },
    {
      onSuccess: () => {
        message.success("Tasdiqlash kodi takroran jo'natildi")
      },
      onError: (err) => {
        message.error(err.response?.data?.message)
      },
    }
  )
}

//create special contract
export function useCreateSpecialContract() {
  const history = useHistory()
  return useMutation(
    async (data) => {
      const res = await $api.post(apis.CREATE_SPECIAL_CONTRACT, data)
      return res.data.data
    },
    {
      onSuccess: () => {
        message.success('Shartnoma yaratildi!')
        history.push('/main/contract')
      },
      onError: (err) => {
        message.error(err.response?.data?.message)
      },
    }
  )
}

// create and update contract parts
export function useCreateContractParts(setModalVisible) {
  const qc = useQueryClient()
  return useMutation(
    async ({ data, contractId }) => {
      const res = await $api.post(
        `${apis.CONTRACT}/${contractId}${apis.PARTS}`,
        data
      )
      return res.data.data
    },
    {
      onSuccess: (_, data) => {
        qc.invalidateQueries(queryNames.PARTS_OF_CONTRACT)
        qc.invalidateQueries(queryNames.CONTRACTS)
        if (data?.firstTime) {
          setModalVisible(true)
        } else {
          setModalVisible(false)
          switch (data?.type) {
            case modalTypes.CREATE:
              return message.success('Partiya yaratildi!')
            case modalTypes.UPDATE:
              return message.success('Partiya tahrirlandi!')
            default:
              return null
          }
        }
      },
      onError: (err) => {
        message.error(err.response?.data?.message)
      },
    }
  )
}

// delete contract part
export function useDeleteContractPart(contractId) {
  const qc = useQueryClient()
  return useMutation(
    async (partId) => {
      const res = await $api.delete(
        `${apis.CONTRACT}/${contractId + apis.PARTS}/${partId}`
      )
      return res.data.data
    },
    {
      onSuccess: () => {
        qc.invalidateQueries(queryNames.PARTS_OF_CONTRACT)
        qc.invalidateQueries(queryNames.CONTRACTS)
        message.success("Partiya muvaffaqiyatli o'chirildi!")
      },
      onError: (err) => {
        message.error(err?.response?.data?.message)
      },
    }
  )
}

// update client
export function useUpdateClient(contractType, clientId, closeModal) {
  const qc = useQueryClient()
  let url = apis.CLIENT

  if (contractType === contractTypes.SPECIFIC) {
    url += `/${contractTypes.SPECIFIC.toLowerCase()}`
  }
  url += `/${clientId}`

  return useMutation(
    async (updateData) => {
      const res = await $api.patch(url, updateData)
      return res.data.data
    },
    {
      onSuccess: async () => {
        qc.invalidateQueries(queryNames.DETAILED_CLIENT)
        await closeModal()
        qc.invalidateQueries(queryNames.CONTRACTS)
        message.success("Mijoz ma'lumotlari yangilandi!")
      },
      onError: (err) => {
        message.error(err?.response?.data?.message)
      },
    }
  )
}

// cancel payment
export function useCancelPayment() {
  const qc = useQueryClient()
  return useMutation(
    async (paymentId) => {
      const res = await $api.delete(`${apis.PAYMENT}/${paymentId}`)
      return res.data.data
    },
    {
      onSuccess: () => {
        qc.invalidateQueries(queryNames.PAYMENTS)
        message.success("To'lov muvaffaqiyatli bekor qilindi!")
      },
    }
  )
}

// cancel payment
export function useCurrencyEdit() {
  const qc = useQueryClient()
  return useMutation(
    async (data) => {
      const res = await $api.patch(`${apis.CURRENCY}`, data)
      return res.data.data
    },
    {
      onSuccess: () => {
        qc.invalidateQueries(queryNames.CURRENCY_CONFIG)
      },
    }
  )
}

export function useCurrencyGetAutoRenew() {
  const qc = useQueryClient()
  return useMutation(
    async () => {
      const res = await $api.get(`${apis.CURRENCY}/auto_renew`)
      return res.data.data
    },
    {
      onSuccess: () => {
        qc.invalidateQueries(queryNames.CURRENCY_CONFIG)
      },
    }
  )
}

export function useContractTable() {
  const { ACTIVE, BLANK, CANCELLED, FINISHED, STARTED } = contractStatus

  return useMutation(
    async ({ searchFields, page, archieve }) => {
      // status
      const status = () => {
        let arr = []

        if (searchFields.statuses?.length > 0) {
          return ''
        }

        if (archieve) {
          arr = [CANCELLED, FINISHED, BLANK]
        } else {
          arr = [ACTIVE, STARTED]
        }

        const stringified = stringify(
          { statuses: arr },
          {
            arrayFormat: 'comma',
          }
        )

        return `${stringified}&`
      }

      const string = stringify(searchFields, {
        arrayFormat: 'comma',
        skipNull: true,
      })

      const res = await $api.get(
        apis.CONTRACTS_ALL_EXCEL + `?page=${page}&size=10&${status()}` + string,
        { responseType: 'blob' }
      )
      return res
    },
    {
      onSuccess: (data) => {
        downloadFile(
          data.data,
          'Jadval ' + moment().format('DD-MM-YYYY HH-mm'),
          'xlsx'
        )
      },
    }
  )
}

export function useSmsSystemDelete() {
  const qc = useQueryClient()
  return useMutation(
    async () => {
      const res = await $api.delete(`${apis.SMS}/system`)
      return res.data.data
    },
    {
      onSuccess: () => {
        message.success("O'chirildi")
        qc.invalidateQueries(queryNames.SMS_SYSTEM)
      },
    }
  )
}

export function useSmsSystemCreate() {
  const qc = useQueryClient()
  return useMutation(
    async (data) => {
      if (data.id) {
        const res = await $api.patch(`${apis.SMS}/system`, data)
        return res.data.data
      } else {
        const res = await $api.post(`${apis.SMS}/system`, data)
        return res.data.data
      }
    },
    {
      onSuccess: () => {
        message.success('Saqlandi')
        qc.invalidateQueries(queryNames.SMS_SYSTEM)
      },
    }
  )
}
