import React from 'react'
import { Layout } from 'antd'

import RouteByRole from './RouteByRole'
import Contract from './contract/Contract'
import ContractIdInfo from './contract/ContractIdInfo'
import Payment from './payment/Payment'
import PaymentPlus from './paymentPlus/Plus'
import Settings from './Settings/Core'
import Arrearage from './arrearage/Arrearage'
import Message from './message/Message'
import ContractCont from './ContractPlus/ContractCont'
import ContractPlusSpecial from './ContractPlus/SpecialContract/ContractPlusSpecial'
import Clients from './clients/Clients'
import DetailedClient from './clients/DetailedClient'
import Broker from '../pages/broker/Broker'
import Blacklist from '../pages/blacklist/Blacklist'

function Content(props) {
  const { path } = props

  return (
    <div>
      <Layout
        style={{
          paddingLeft: 275,
          backgroundColor: '#fafbfd',
          minHeight: '100vh',
        }}
      >
        <RouteByRole path={path} page="/contract">
          <Contract />
        </RouteByRole>
        <RouteByRole path={path} page="/contract/:id">
          <ContractIdInfo />
        </RouteByRole>
        <RouteByRole path={path} page="/contractPlus">
          <ContractCont />
        </RouteByRole>
        <RouteByRole path={path} page="/contractPlusSpecial">
          <ContractPlusSpecial />
        </RouteByRole>
        <RouteByRole path={path} page="/payment">
          <Payment />
        </RouteByRole>
        <RouteByRole path={path} page="/paymentPlus">
          <PaymentPlus />
        </RouteByRole>
        <RouteByRole path={path} page="/arrearage">
          <Arrearage />
        </RouteByRole>
        <RouteByRole path={path} page="/settings">
          <Settings />
        </RouteByRole>
        <RouteByRole path={path} page="/message">
          <Message />
        </RouteByRole>
        <RouteByRole path={path} page="/client">
          <Clients />
        </RouteByRole>
        <RouteByRole path={path} page="/client/:id">
          <DetailedClient />
        </RouteByRole>
        <RouteByRole path={path} page="/broker">
          <Broker />
        </RouteByRole>
        <RouteByRole path={path} page="/blacklist">
          <Blacklist />
        </RouteByRole>
      </Layout>
    </div>
  )
}

export default Content
