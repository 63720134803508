export const queryNames = {
  EMPLOYEE_SELECTS: 'EMPLOYEE_SELECTS',
  CONTRACTS: 'CONTRACTS',
  INFO: 'INFO',
  PAYMENT_PLUS_TABLES: 'PAYMENT_PLUS_TABLES',
  CONTRACTS_SEARCH: 'CONTRACTS_SEARCH',
  PAYMENTS: 'PAYMENTS',
  PAYMENTS_SUM: 'PAYMENTS_SUM',
  ARREARAGE: 'ARREARAGE',
  MESSAGE: 'MESSAGE',
  MESSAGE_TEXT: 'MESSAGE_TEXT',
  BUILDING_COMPANY: 'BUILDING_COMPANY',
  ONE_COMPANY: 'ONE_COMPANY',
  SMS_SETTINGS: 'SMS_SETTINGS',
  PRODUCT_SEARCH: 'PRODUCT_SEARCH',
  CLIENTS: 'CLIENTS',
  DETAILED_CLIENT: 'DETAILED_CLIENT',
  CONTRACTS_OF_CLIENT: 'CONTRACTS_OF_CLIENT',
  PARTS_OF_CONTRACT: 'PARTS_OF_CONTRACT',
  FIND_CLIENT_SEARCH_BY_PASSPORT_DATA: 'FIND_CLIENT_SEARCH_BY_PASSPORT_DATA',
  CHECK_NEW_VERSION: 'CHECK_NEW_VERSION',
  CURRENCY_CONFIG: 'CURRENCY_CONFIG',
  SMS_SYSTEM: 'SMS_SYSTEM',
}
