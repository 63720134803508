import { Col, Input, message, Popconfirm, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { FiSave } from 'react-icons/fi'
import { RiDeleteBin5Fill } from 'react-icons/ri'
import { useDispatch } from 'react-redux'
import { productAdd, productDelete } from '../../../redux/actions'
import GlobalAssistant from '../../../services/GlobalAssistant'
import { locales } from '../../../utils/constants/locales'
import { customCurrency } from '../../../utils/helper/customCurrency'
import { parseLocaledString } from '../../../utils/helper/parseLocaledString'
import { useGetCurrency } from '../../../queries/queries'
import { deleteNotNumbersAndParseFloatNumber } from '../../../utils/helper/deleteNotNumbersAndParseFloatNumber'

const initialProductState = {
  isSave: false,
  count: null,
  productDto: {
    name: null,
    price: null,
    currencyType: null,
  },
}

const SpecialProduct = ({ data, index, save, currency }) => {
  const { data: currencyConfig } = useGetCurrency()
  const exchange = currencyConfig?.exchange
  const [product, setProduct] = useState(initialProductState)
  // const [selectedProductId, setSelectedProductId] = useState()
  // const productSearch = useProductSearch(product.productDto.name)
  const dispatch = useDispatch()
  useEffect(() => {
    if (save !== 0 && save) {
      saveProductFunc()
    }
    // eslint-disable-next-line
  }, [save])

  useEffect(() => {
    if (data) {
      setProduct({
        isSave: data.isSave,
        count: data?.count,
        productDto: {
          name: data?.productDto?.name,
          price: customCurrency(String(data?.productDto?.price)),
          currencyType: data?.productDto?.currencyType,
        },
      })
    }

    // eslint-disable-next-line
  }, [data])

  const saveProductFunc = (e) => {
    e.preventDefault()
    if (
      product.count &&
      product?.productDto.name &&
      product?.productDto.price
    ) {
      const saveObj = {
        count: product.count,
        isSave: true,
        productDto: {
          ...product.productDto,
          price: parseLocaledString(product.productDto.price),
        },
      }
      dispatch(productAdd(saveObj, index))
      if (index === 'new') {
        setProduct(initialProductState)
      }
    } else {
      message.error("Ma'lumotlar xato to'ldirilgan!")
    }
  }

  const deleteFunc = () => {
    dispatch(productDelete(index))
  }

  // const optionItem = (item) => ({
  //   id: item.id,
  //   value: item.name,
  //   label: (
  //     <div style={{ display: 'flex' }}>
  //       {item.name}
  //       <span style={{ display: 'block', marginLeft: 'auto' }}>
  //         <IoPricetag />
  //         &nbsp;
  //         {item.price.toLocaleString()}
  //       </span>
  //     </div>
  //   ),
  // })
  // const option = productSearch.data?.map((item) => optionItem(item))

  // const handleSelect = (_, label) => {
  //   setSelectedProductId(label.id)
  //   setProduct({
  //     count: product?.count,
  //     isSave: false,
  //     productDto: {
  //       name: label.value,
  //       price: product?.productDto?.price,
  //     },
  //   })
  // }

  // const defaultProductPrice = () => {
  //   if (selectedProductId) {
  //     const product = productSearch?.data?.find(
  //       (item) => item.id === selectedProductId
  //     )
  //     return product?.price?.toLocaleString()
  //   } else {
  //     return null
  //   }
  // }
  const convertPrice = (price, valyuta) => {
    if (valyuta === currency) {
      return price
    } else {
      if (currency === 'USD') {
        return price / exchange
      } else {
        return price * exchange
      }
    }
  }
  return (
    <Row className={'body'} key={index}>
      <Col span={7}>
        {/* <AutoComplete
          options={option}
          dropdownMatchSelectWidth={500}
          onSelect={handleSelect}
        > */}
        {/* {productSearch?.data?.map((item) => (
            <AutoComplete.Option key={item.id} value={item.name} />
          ))} */}
        <Input
          value={product?.productDto?.name}
          onChange={(e) => {
            setProduct({
              count: product?.count,
              isSave: false,
              productDto: {
                ...product?.productDto,
                name: e.target.value,
              },
            })
          }}
          onPressEnter={(e) => {
            saveProductFunc(e)
          }}
          placeholder={'Mahsulot nomi'}
          className={'input'}
        />
        {/* </AutoComplete> */}
      </Col>
      <Col span={4}>
        <Input
          value={product?.count}
          type={'number'}
          onPressEnter={(e) => {
            saveProductFunc(e)
          }}
          onChange={(e) => {
            const count = GlobalAssistant.deleteNunNumbers(e.target.value)
            setProduct({
              count: parseInt(count),
              isSave: false,
              productDto: product?.productDto,
            })
          }}
          placeholder={'Soni'}
          className={'input'}
        />
      </Col>
      <Col span={5}>
        <Input
          value={
            // product?.productDto?.price
            //   ?
            product?.productDto?.price
            // : defaultProductPrice()
          }
          // defaultValue={defaultProductPrice()}
          onChange={(e) => {
            let price = customCurrency(e.target.value)

            setProduct({
              count: product?.count,
              isSave: false,
              productDto: {
                ...product?.productDto,
                price: price,
              },
            })
          }}
          onPressEnter={(e) => {
            saveProductFunc(e)
          }}
          placeholder={'Narxi'}
          className={'input'}
        />
      </Col>
      {/* <Col className={'sum'} span={5}>
        <Select
          style={{ width: 100, marginRight: 32 }}
          value={product?.productDto?.currencyType}
          onChange={(value) => {
            setProduct({
              ...product,
              productDto: {
                ...product?.productDto,
                currencyType: value,
              },
            })
          }}
        >
          <Select.Option value={'USD'}>USD</Select.Option>
          <Select.Option value={'UZS'}>UZS</Select.Option>
        </Select>
        <div className="currencySumColumn">
          <p>
            {deleteNotNumbersAndParseFloatNumber(
              product?.count * parseLocaledString(product?.productDto?.price)
            ).toLocaleString(locales.RU)}{' '}
            {product?.productDto?.currencyType}
          </p>
          <p>
            {convertPrice(
              deleteNotNumbersAndParseFloatNumber(
                product?.count * parseLocaledString(product?.productDto?.price)
              ),
              product?.productDto?.currencyType
            ).toLocaleString(locales.RU)}{' '}
            {currency}
          </p>
        </div>
      </Col> */}
      <Col className={'sum'} span={5}>
        {(
          product?.count * parseLocaledString(product?.productDto?.price)
        ).toLocaleString(locales.RU)}{' '}
        {currency}
      </Col>

      <Col span={3} className={'iconsCont'}>
        <FiSave
          onClick={saveProductFunc}
          className={`save${product?.isSave ? 'active' : 'noActive'}`}
        />
        {index !== 'new' && (
          <Popconfirm
            placement="topRight"
            title={"O'chirishni tasdiqlash"}
            onConfirm={deleteFunc}
            okText="ha"
            cancelText="yo'q"
          >
            <RiDeleteBin5Fill className={'delete'} />
          </Popconfirm>
        )}
      </Col>
    </Row>
  )
}

export default SpecialProduct
