import { combineReducers } from 'redux'

import clientReducer from './clientReducer'
import contractReducer from './contractReducer'
import paymentReducer from './paymentReducer'
import productReducer from './productReducer'
import projectVersionReducer from './projectVersionReducer'
import sponsorReducer from './sponsorReducer'

export const rootReducer = combineReducers({
  clientCont: clientReducer,
  contractCont: contractReducer,
  paymentCont: paymentReducer,
  productCont: productReducer,
  sponsorCont: sponsorReducer,
  projectVersion: projectVersionReducer,
})
