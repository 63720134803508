import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useLoginMutation } from '../queries/mutations'
import { Button, Col, Form, Input, Row, Typography } from 'antd'
import { PoweroffOutlined } from '@ant-design/icons'
import AuthService from '../services/AuthService'

const { Title, Text } = Typography

function Login() {
  const [form] = Form.useForm()
  const history = useHistory()

  const logIn = useLoginMutation(history)

  const onFinish = (val) => {
    logIn.mutate(val)
  }
  useEffect(() => {
    if (AuthService.isSigned()) {
      const user = AuthService.getCurrentUser()

      try {
        const url = AuthService.getLandingPage(user?.roles[0])
        history.push(url)
      } catch (e) {
        localStorage.clear()
        window.location.reload()
      }
    }
  }, [history])
  return (
    <div style={{ backgroundColor: '#fafbfd', height: '100vh' }}>
      <Row justify="space-around" align="middle">
        <Col flex="430px" style={{ background: '#F9F9F9', padding: 85 }}>
          <Title style={{ textAlign: 'center', fontSize: 64 }}>Login</Title>
          <Title
            style={{
              textAlign: 'center',
              fontSize: 12,
              marginBottom: 36,
              marginTop: -16,
            }}
          >
            faqat ofis ishchilari uchun
          </Title>
          <Form layout={'vertical'} form={form} onFinish={onFinish}>
            <Form.Item
              label="Username"
              name="username"
              rules={[{ required: true, message: 'Loginni kiriting!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Parolni kiriting!' }]}
            >
              <Input.Password />
            </Form.Item>

            <Text type="danger">
              {logIn.isError ? (
                'Login yoki parol xato, iltimos qayta kiriting'
              ) : (
                <div>
                  <br />
                  <br />
                </div>
              )}
            </Text>

            <Form.Item>
              <Button
                icon={<PoweroffOutlined />}
                loading={logIn.isLoading}
                style={{ width: '100%' }}
                type="primary"
                htmlType="submit"
              >
                Login
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  )
}

export default Login
