///////////// CLIENT TYPES
export const CLIENT_DATA = 'CLIENT_DATA'
export const CLIENT_PHONES = 'CLIENT_PHONES'
export const CLIENT_CLEAR = 'CLIENT_CLEAR'
export const CLIENT_RESTORE = 'CLIENT_RESTORE'

//////////// CONTRACT TYPES
export const CONTRACT_OPEN_PAGE = 'CONTRACT_OPEN_PAGE'
export const CONTRACT_DATE = 'CONTRACT_DATE'
export const CONTRACT_SAVE = 'CONTRACT_SAVE'

/////////// PRODUCT TYPES
export const PRODUCT_LIST = 'PRODUCT_LIST'
export const SET_PRODUCT_LIST = 'SET_PRODUCT_LIST'

/////////// PAYMENT TYPES
export const PAYMENT_DATA = 'PAYMENT_DATA'
export const PAYMENT_TABLE = 'PAYMENT_TABLE'
export const PAYMENT_DELAY = 'PAYMENT_DELAY'
export const PAYMENT_FIRST_DATE = 'PAYMENT_FIRST_DATE'
export const PAYMENT_RANGE = 'PAYMENT_RANGE'
export const PAYMENT_RESIDUE = 'PAYMENT_RESIDUE'
export const PAYMENT_CLEAR = 'PAYMENT_CLEAR'
export const PAYMENT_TABLE_COUNT = 'PAYMENT_TABLE_COUNT'

/////////// SPONSOR TYPES
export const ADD_SPONSOR = 'ADD_SPONSOR'
export const DELETE_SPONSOR = 'DELETE_SPONSOR'
export const UPDATE_SPONSOR = 'UPDATE_SPONSOR'
export const CLEAR_SPONSOR = 'CLEAR_SPONSOR'

////////////PROJECT VERSION
export const SET_PROJECT_VERSION = 'SET_PROJECT_VERSION'
