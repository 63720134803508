import { Button, DatePicker, Input, message, Modal, Select } from 'antd'
import { Option } from 'antd/es/mentions'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import {
  useDownloadPaymentMutation,
  usePaymentMutation,
} from '../../queries/mutations'
import { customCurrency } from '../../utils/helper/customCurrency'
import { parseLocaledString } from '../../utils/helper/parseLocaledString'

function disabledDate(current) {
  return current && current > moment().endOf()
}

const PayModal = ({ closeModal, contractId, modalOpen, activeCurrency }) => {
  const [paymentObj, setPaymentObj] = useState({
    payType: 'CASH',
    payValue: null,
    date: moment(new Date()).format('DD.MM.YYYY HH:mm:ss'),
    currencyType: activeCurrency,
  })

  const countClickRef = useRef(1)

  const clearRefFunc = () => {
    countClickRef.current = 1
  }

  const closeFunc = () => {
    countClickRef.current = 1
    setPaymentObj({
      payType: 'CASH',
      payValue: null,
      date: moment(new Date()).format('DD.MM.YYYY HH:mm:ss'),
      currency: activeCurrency,
    })
    closeModal()
  }

  const downloadWord = useDownloadPaymentMutation()
  const paymentCreate = usePaymentMutation(
    closeFunc,
    clearRefFunc,
    downloadWord
  )

  const payFunc = () => {
    if (countClickRef.current === 1) {
      if (!paymentObj.payValue && !paymentObj.payType) {
        message.error("To'lov turi va summasi kiritilmagan")
      } else if (!paymentObj.payValue) {
        message.error('Summa kiritilmagan')
      } else if (!paymentObj.payType) {
        message.error("To'lov turi kiritilmagan")
      } else {
        countClickRef.current = countClickRef.current + 1
        paymentCreate.mutate({
          id: contractId,
          paymentBody: {
            amount: parseLocaledString(paymentObj.payValue),
            type: paymentObj.payType,
            createdDate: paymentObj.date,
            currencyType: paymentObj.currencyType,
          },
        })
      }
    } else {
      message.warn("So'rov yuborilgan")
    }
  }
  useEffect(() => {
    setPaymentObj({
      ...paymentObj,
      currencyType: activeCurrency,
    })
  }, [activeCurrency])

  return (
    <div>
      <Modal
        visible={modalOpen}
        title={"To'lov qilish"}
        onCancel={closeFunc}
        footer={[
          <DatePicker
            showTime
            disabledDate={disabledDate}
            style={{ marginRight: '10px', width: '170px' }}
            allowClear={false}
            format="DD.MM.YYYY HH:mm:ss"
            value={moment(paymentObj.date, 'DD-MM-YYYY HH:mm:ss')}
            onChange={(date, dateString) => {
              setPaymentObj({
                ...paymentObj,
                date: dateString,
              })
            }}
          />,
          <Button
            loading={paymentCreate.isLoading}
            onClick={payFunc}
            type={'primary'}
          >
            Odatiy to'lov
          </Button>,
        ]}
        width={650}
      >
        <div
          style={{
            display: 'flex',
          }}
        >
          <div style={{ width: '40%' }}>
            <label className={'payModalLabel'} htmlFor={'payModalLabel'}>
              Summa:
            </label>
            <Input
              placeholder={'Summa...'}
              id={'payModalLabel'}
              className={'inputNumberStyle payModal'}
              value={paymentObj.payValue}
              onChange={(e) => {
                let changedValue = customCurrency(e.target.value)
                setPaymentObj({
                  ...paymentObj,
                  payValue: changedValue,
                })
              }}
            />
          </div>
          <div style={{ width: '40%' }}>
            <label className={'payModalLabel'} htmlFor={'payModalLabel'}>
              To'lov turi:
            </label>
            <Select
              style={{ width: '60%' }}
              placeholder="To`lov turi"
              value={paymentObj.payType}
              onChange={(e) => {
                setPaymentObj({
                  ...paymentObj,
                  payType: e,
                })
              }}
            >
              <Option key={'CASH'} value={'CASH'}>
                {'Naqd'}
              </Option>
              <Option key={'TRANSFER'} value={'TRANSFER'}>
                {'P2P'}
              </Option>
              <Option key={'BANK'} value={'BANK'}>
                {'Bank'}
              </Option>
              <Option key={'CARD'} value={'CARD'}>
                {'Karta'}
              </Option>
            </Select>
          </div>
          <div style={{ width: '20%' }}>
            <Select
              style={{ width: '60%' }}
              value={paymentObj.currencyType}
              onChange={(value) => {
                setPaymentObj({ ...paymentObj, currencyType: value })
              }}
            >
              <Option value="UZS">UZS</Option>
              <Option value="USD">USD</Option>
            </Select>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default PayModal
